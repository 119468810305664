import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  Linking,
  Platform,
  Image,
  View,
  ScrollView,
  SafeAreaView,
} from "react-native";

import {
  Paragraph,
  Colors,
  Portal,
  Dialog,
  useTheme,
  Switch,
  TextInput,
  HelperText,
  Text,
} from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import MenuOperators from "./SmartFormMenuOperators";

import SimpleText from "../Input/SimpleText";

const SmartFormTextInput = ({
  visible,
  title,
  name,
  control,
  customError,
  placeholder,
  isQuery,
}: {
  visible?: boolean | undefined;
  title?: string;
  name?: string;
  control?: any | undefined;
  customError?: string;
  placeholder?: string;
  isQuery?: boolean | undefined;
}) => {
  if (visible === false) return null;
  const operators = [
    { name: "Not set", value: "" },
    { name: "Contain", value: "contain" },
    { name: "Not Contain", value: "notcontain" },
    { name: "Equal", value: "eq" },
    { name: "Not equal", value: "ne" },
    { name: "Regex", value: "regex" },
  ];
  // const [operator, setOperator] = useState(operators[0].value);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <View style={styles.row}>
          <View>
            <MenuOperators
              title={title}
              visible={isQuery}
              operators={operators}
              operator={value?.op}
              setOperator={(v) => {
                onChange({ ...value, op: v });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              marginHorizontal: 16,
            }}
          >
            {(() => {
              if (isQuery && !value?.op) return <Text>n/a</Text>;
              return (
                <SimpleText
                  // title={title}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={`${value}`}
                  invalid={invalid}
                  customError={customError}
                  error={error}
                  operator={value?.op}
                />
              );
            })()}
          </View>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  dialogInputContainerStyle: {
    marginHorizontal: 8,
  },
});

export default SmartFormTextInput;
