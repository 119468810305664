import React from "react";
import { ScrollView, StyleSheet, FlatList, Platform } from "react-native";
import { useTheme, Text } from "react-native-paper";
import { useSafeArea } from "react-native-safe-area-context";

const ListView = ({
  ViewListRowItem,
  ViewListItemEdit,
  gql,
  loading,
  error,
  items,
}) => {
  const item = ({ item }) => (
    <ViewListRowItem
      item={item}
      ViewListItemEdit={ViewListItemEdit}
      gql={gql}
    />
  );

  const { colors } = useTheme();
  const safeArea = useSafeArea();

  if (loading) return null;
  if (error) return <Text>`Error! ${error.message}`</Text>;

  return (
    <FlatList
      contentContainerStyle={{
        backgroundColor: colors.background,
        paddingBottom: safeArea.bottom,
        paddingLeft: safeArea.left,
        paddingRight: safeArea.right,
        ...(Platform.OS === "web" && {
          position: "absolute",
          width: "100%",
          height: "100%",
        }),
      }}
      style={{ backgroundColor: colors.background }}
      renderItem={item}
      keyExtractor={(item) => item._id}
      data={items}
      initialNumToRender={10}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 4,
  },
  card: {
    margin: 12,
  },
  cover: {
    flex: 3,
  },
  content: { flex: 9 },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 0,
    paddingHorizontal: 16,
  },
  label: {
    flex: 2,
  },
  data: {
    flex: 10,
  },
  checkBoxGroup: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionButtons: {
    marginHorizontal: 8,
  },
  surface: {
    padding: 8,
    height: 80,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
    elevation: 4,
  },
});
export default ListView;
