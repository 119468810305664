import React, { useMemo } from "react";
import {
  SmartFormToggle,
  SmartFormTextInput,
  SmartFormDatePicker,
  SmartFormRadioGroup,
  SmartFormTextArea,
  SmartFormCheckboxGroup,
  SmartFormNumberInput,
} from "../../Components/SmartForm";

const SearchFormContent = ({ control, extented }) => {
  const {
    EnumMsgTemplateTypeLoading,
    EnumMsgTemplateTypeError,
    EnumMsgTemplateTypeData,
    EnumSortByValue,
  } = extented;

  //* TODO *//
  const sortableFields = useMemo(
    () => EnumSortByValue.map((v) => ({ name: v, value: v })),
    []
  );

  const typeOptions = useMemo(
    () =>
      EnumMsgTemplateTypeLoading || EnumMsgTemplateTypeError
        ? []
        : EnumMsgTemplateTypeData?.__type?.enumValues.map((v) => ({
            name: v.name,
            value: v.name,
          })),
    [EnumMsgTemplateTypeData]
  );
  return (
    <>
      <SmartFormNumberInput
        title="Tid"
        name="tid"
        placeholder="tid"
        control={control}
        isQuery
        min={0}
      />
      <SmartFormRadioGroup
        title="Type"
        name="type"
        control={control}
        isQuery
        options={typeOptions}
      />
      <SmartFormTextInput
        title="Name"
        name="name"
        placeholder="Enter Template Name"
        control={control}
        isQuery
      />
      <SmartFormTextInput
        title="Title"
        name="title"
        placeholder="Enter Template Title"
        control={control}
        isQuery
      />
      <SmartFormTextInput
        title="Body"
        name="body"
        placeholder="Enter Template body"
        control={control}
        isQuery
      />
      <SmartFormRadioGroup
        title="Sort By"
        name="sortField"
        control={control}
        options={sortableFields}
      />
      <SmartFormToggle
        title="Sort Ascending"
        name="sortAscending"
        control={control}
      />
    </>
  );
};

export default SearchFormContent;
