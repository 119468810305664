import { gql } from "@apollo/client";
import graphql from "graphql";

export const GetFieldsByName = (name) => gql`
  query SettingFields {
    __type(name: "${name}") {     
      __typename
      name 
      fields {
        name
        description
        type {
          name
        }
      }
    }
  }
`;

export const GetFields = gql`
  query SettingFields {
    __type(name: "Setting") {
      __typename
      name
      fields {
        name
        description
        type {
          name
        }
      }
    }
  }
`;

export const SettingManual = gql`
  query Settings {
    settingOne {
      sitename
      updatedAt
      createdAt
    }
  }
`;

export const Setting = (SettingFields) => gql`
  query Settings {
    settingOne {
      ${SettingFields || "updatedAt"}
    }
  }
`;

export const Update = gql`
  mutation settingUpdate($settingUpdate: UpdateOneSettingInput!) {
    settingUpdate(record: $settingUpdate) {
      _id
    }
  }
`;

export const GetContactDefaultemailfrom = gql`
  query {
    setting: settingOne {
      contact {
        defaultemailfrom
      }
    }
  }
`;
