import * as React from "react";
import { View, StyleSheet } from "react-native";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import {
  Badge,
  Drawer,
  Switch,
  TouchableRipple,
  Text,
  Colors,
  useTheme,
} from "react-native-paper";
import * as Updates from "expo-updates";

import { useNavigation } from "@react-navigation/native";

const DrawerItemsData = [
  { label: "Projects", icon: "stack-overflow", key: 0 },
  { label: "Message Templates", icon: "card-text-outline", key: 1 },
  { label: "Settings", icon: "cog", key: 2 },
  { label: "Examples", icon: "inbox", key: 3 },
  {
    label: "Starred",
    icon: "star",
    key: 4,
    right: ({ color }) => (
      <Badge
        visible={true}
        size={8}
        style={[styles.badge, { backgroundColor: color }]}
      />
    ),
  },
  { label: "Sent mail", icon: "send", key: 5 },
  { label: "Colored label", icon: "palette", key: 6 },
  { label: "A very long title that will be truncated", icon: "delete", key: 7 },
];
const DrawerItems = ({ toggleTheme, toggleRTL, isRTL, isDarkTheme }) => {
  const [drawerItemIndex, setDrawerItemIndex] = React.useState(0);
  const _setDrawerItem = (index) => setDrawerItemIndex(index);
  const { colors } = useTheme();
  const _handleToggleRTL = () => {
    toggleRTL();
    Updates.reloadAsync();
  };
  const navigation = useNavigation();
  return (
    <DrawerContentScrollView
      alwaysBounceVertical={false}
      style={[styles.drawerContent, { backgroundColor: colors.surface }]}
    >
      <Drawer.Section title="Example items">
        {DrawerItemsData.map((props, index) => (
          <Drawer.Item
            {...props}
            key={props.key}
            theme={
              props.key === 3
                ? { colors: { primary: Colors.tealA200 } }
                : undefined
            }
            active={drawerItemIndex === index}
            onPress={() => {
              _setDrawerItem(index);

              navigation.navigate(props.label);
              navigation.reset({
                routes: [{ name: props.label }],
              });
            }}
          ></Drawer.Item>
        ))}
      </Drawer.Section>
      <Drawer.Section title="Preferences">
        <TouchableRipple onPress={toggleTheme}>
          <View style={styles.preference}>
            <Text>Dark Theme</Text>
            <View pointerEvents="none">
              <Switch value={isDarkTheme}></Switch>
            </View>
          </View>
        </TouchableRipple>
      </Drawer.Section>
      <TouchableRipple onPress={_handleToggleRTL}>
        <View style={styles.preference}>
          <Text>Text</Text>
          <View pointerEvents="none">
            <Switch value={isRTL}></Switch>
          </View>
        </View>
      </TouchableRipple>
    </DrawerContentScrollView>
  );
};
const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  badge: {
    alignSelf: "center",
  },
});
export default DrawerItems;
