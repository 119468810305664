import React from "react";

import {
  SmartFormToggle,
  SmartFormTextInput,
  SmartFormDatePicker,
  SmartFormRadioGroup,
  SmartFormTextArea,
  SmartFormCheckboxGroup,
  SmartFormNumberInput,
  SmartFormRichTextEditor,
} from "../../Components/SmartForm";

const NewFormContent = ({ control, extented }) => {
  const {
    EnumMsgTemplateTypeLoading,
    EnumMsgTemplateTypeError,
    EnumMsgTemplateTypeData,
  } = extented;
  return (
    <>
      <SmartFormRadioGroup
        title="Type"
        name="type"
        control={control}
        options={
          EnumMsgTemplateTypeLoading || EnumMsgTemplateTypeError
            ? []
            : EnumMsgTemplateTypeData?.__type?.enumValues.map((v) => ({
                name: v.name,
                value: v.name,
              }))
        }
      />
      <SmartFormTextInput
        title="Name"
        name="name"
        placeholder="Enter Name for the template"
        control={control}
      />
      <SmartFormTextInput
        title="Title"
        name="title"
        placeholder="Enter Title for the template"
        control={control}
      />
      <SmartFormRichTextEditor
        name="body"
        placeholder="Design you template here"
        control={control}
      />

      {/* <SmartFormTextArea
        title="Body"
        name="body"
        placeholder="Enter Content"
        numberOfLines={20}
        control={control}
      /> */}
    </>
  );
};

export default NewFormContent;
