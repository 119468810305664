import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  Linking,
  Platform,
  Image,
  View,
  ScrollView,
} from "react-native";
import {
  DataTable,
  Button,
  TextInput,
  Checkbox,
  useTheme,
  Text,
} from "react-native-paper";

import ScreenWrapper from "../../ScreenWrapper";
import {} from "react-native-paper";
import moment from "moment";
import axios from "axios";
import _, { debounce } from "lodash";

import { useQuery, gql, useMutation } from "@apollo/client";
import { UpdateById } from "./gql";

const ImgDisplay = ({ url }) => {
  const [w, setW] = useState(0);
  const [h, setH] = useState(0);

  return (
    <DataTable.Cell
      style={[styles.preview, { flexDirection: "column" }]}
      onLayout={(event) => {
        var { x, y, width, height } = event.nativeEvent.layout;
        if (url && !w && !h) {
          Image.getSize(url, (_w, _h) => {
            let desiredWidth = width;
            let newHeight = (desiredWidth / _w) * _h;

            setW(desiredWidth);
            setH(newHeight);
          });
        }
      }}
    >
      <Image
        source={(url && { uri: url }) || null}
        style={{ width: w, height: h }}
        resizeMode={"contain"}
      />
    </DataTable.Cell>
  );
};

const RowItem = ({ item }) => {
  const [notes, setNotes] = useState(item.notes);
  const [checkEmailCrawer, setCheckEmailCrawer] = useState(
    !!item?.emailcrawler?.enable
  );

  const [updateProjectFunction] = useMutation(UpdateById, {
    onCompleted: (data) => {
      console.log("Update Completed!", data);
    },
    onError: (e) => {
      // console.log("error", e);
      alert("updateChanged " + e);
    },
  });

  const debounceChange = useCallback(
    debounce(
      (key, update) =>
        updateProjectFunction({
          variables: { _id: key, update },
        }),
      2000
    ),
    [] // will be created only once initially
  );

  return (
    <DataTable.Row key={item._id}>
      <DataTable.Cell style={styles.sid}>{item.sid}</DataTable.Cell>
      <DataTable.Cell style={styles.date}>
        {item.date ? moment(item.date).format("YYYY-MM-DD hh:mm") : "null"}
      </DataTable.Cell>
      <DataTable.Cell style={styles.status}>
        {item.active ? "Active" : "Inactive"}
      </DataTable.Cell>
      <DataTable.Cell style={styles.lastCrawled}>
        {item.lastCrawled
          ? moment(item.lastCrawled).format("YYYY-MM-DD hh:mm")
          : "null"}
      </DataTable.Cell>
      <DataTable.Cell style={styles.source}>{item.source}</DataTable.Cell>
      <DataTable.Cell style={styles.slug}>{item.slug}</DataTable.Cell>
      <ImgDisplay url={item.photo}></ImgDisplay>
      {/* <DataTable.Cell
          style={[styles.preview, { flexDirection: "column" }]}
          onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;
            console.log("ON Layout", width);
          }}
        >
          <ImgDisplay url={item.photo}></ImgDisplay>
        </DataTable.Cell> */}
      <DataTable.Cell style={styles.name}>{item.name}</DataTable.Cell>
      <DataTable.Cell style={styles.amount}>
        USD${item.pledged_usd.toFixed(1)}
      </DataTable.Cell>
      <DataTable.Cell style={styles.precent}>
        {item.pledged_persentage}%
      </DataTable.Cell>
      <DataTable.Cell style={styles.backers}>
        {item.backers_count}
      </DataTable.Cell>
      <DataTable.Cell style={styles.creator}>{item.creator}</DataTable.Cell>
      <DataTable.Cell style={styles.category}>{item.category}</DataTable.Cell>
      <DataTable.Cell style={styles.link}>
        <Text
          accessibilityRole="link"
          style={[styles.text]}
          onPress={() => {
            if (Platform.OS == "web") {
              window.open(item.link, "_blank");
            } else {
              Linking.openURL(item.link);
            }
          }}
        >
          {item.link}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={styles.notes}>
        <TextInput
          mode="outlined"
          style={styles.inputContainerStyle}
          label="Notes"
          multiline
          placeholder="Type something"
          value={notes}
          onChangeText={(i) => {
            debounceChange(item._id, { notes: i });
            setNotes(i);
          }}
        />
      </DataTable.Cell>
      <DataTable.Cell style={styles.email}>
        <View>
          {(item?.emailcrawler?.result?.length &&
            item.emailcrawler.result.map((result) => (
              <Text style={{ flex: 1, flexWrap: "wrap" }} key={result}>
                {result}
              </Text>
            ))) ||
            null}
          <Checkbox.Item
            label="Enable Crawer"
            status={checkEmailCrawer ? "checked" : "unchecked"}
            onPress={() => {
              let result = !checkEmailCrawer;
              setCheckEmailCrawer(!checkEmailCrawer);
              debounceChange(item._id, {
                emailcrawler: { enable: result },
              });
            }}
          />
          {item.emailcrawler.lastRun && (
            <Text>
              Last Craw:
              {moment(item.emailcrawler.lastRun).format("YYYY-MM-DD")}
            </Text>
          )}
          {item.emailcrawler.runTime && (
            <Text>Run time: {item.emailcrawler.runTime}</Text>
          )}
        </View>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

const ViewTable = ({ loading, error, items }) => {
  return (
    <ScrollView>
      <ScrollView horizontal={true}>
        <DataTable style={{ flex: 1 }}>
          <DataTable.Header>
            <DataTable.Title style={styles.sid}>Sid</DataTable.Title>
            <DataTable.Title style={styles.date}>Date</DataTable.Title>
            <DataTable.Title style={styles.status}>Status</DataTable.Title>
            <DataTable.Title style={styles.lastCrawled}>
              LastCrawled
            </DataTable.Title>
            <DataTable.Title style={styles.source}>Source</DataTable.Title>
            <DataTable.Title style={styles.slug}>Project Slug</DataTable.Title>
            <DataTable.Title style={styles.preview}>Preview</DataTable.Title>
            <DataTable.Title style={styles.name}>Project Name</DataTable.Title>
            <DataTable.Title style={styles.amount}>
              Pledge amount
            </DataTable.Title>
            <DataTable.Title style={styles.precent}>Pledged %</DataTable.Title>
            <DataTable.Title style={styles.backers}>Backers</DataTable.Title>
            <DataTable.Title style={styles.creator}>Creator</DataTable.Title>
            <DataTable.Title style={styles.category}>Category</DataTable.Title>
            <DataTable.Title style={styles.link}>Link</DataTable.Title>
            <DataTable.Title style={styles.notes}>Notes</DataTable.Title>
            <DataTable.Title style={styles.email}>
              Email Crawler
            </DataTable.Title>
          </DataTable.Header>
          {loading ? null : error ? (
            <Text>`Error! ${error.message}`</Text>
          ) : (
            <>
              {items.map((item) => (
                <RowItem key={item._id} item={item} />
              ))}
            </>
          )}
        </DataTable>
      </ScrollView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: 12,
  },

  button: {
    margin: 4,
  },
  scrollView: {},
  sid: {
    flex: 1,
  },
  date: {
    flex: 2,
  },
  status: {
    flex: 1,
  },
  lastCrawled: {
    flex: 1,
  },
  source: {
    flex: 1,
  },
  slug: {
    flex: 3,
  },
  preview: {
    flex: 5,
  },
  name: {
    flex: 4,
    paddingRight: 20,
  },
  amount: {
    flex: 1,
  },
  precent: {
    flex: 1,
  },
  backers: {
    flex: 1,
  },
  creator: {
    flex: 1,
  },
  category: {
    flex: 1,
  },
  link: {
    flex: 5,
  },
  notes: {
    flex: 2,
  },
  email: {
    flex: 4,
  },
  content: {
    padding: 8,
    flex: 1,
  },

  image: {
    borderColor: "black",
    borderWidth: 0.5,
    // height: 120,
    // width: 360,
    // resizeMode: "cover",
    // height: "100%",
    // width: "100%",
    flex: 1,
    height: undefined,
    width: undefined,
    backgroundColor: "#fcc",
  },
  inputSearchContainerStyle: {
    maxWidth: "100%",
  },
  inputContainerStyle: {
    margin: 0,
  },
  rowswitch: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
  },

  dialogInputContainerStyle: {
    marginHorizontal: 8,
  },

  emailArray: {
    flexWrap: "wrap",
    overflow: "visible",
  },
  text: {
    // flex: 1,
    // flexWrap: "wrap",
  },
});

export default ViewTable;
