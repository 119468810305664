import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import {
  StyleSheet,
  Linking,
  Platform,
  Image,
  View,
  ScrollView,
  SafeAreaView,
  Dimensions,
} from "react-native";
import {
  DataTable,
  Button,
  TextInput,
  Checkbox,
  useTheme,
  Text,
  Searchbar,
  RadioButton,
  FAB,
  Appbar,
  ToggleButton,
} from "react-native-paper";

import { useQuery, gql, useMutation } from "@apollo/client";

const GererateSearchTable = ({
  title,
  gql,
  NewDialog,
  QueryDialog,
  TableView,
  ListView,
  defaultView,
  defaultSort,
}) => {
  const SearchTable = ({ navigation }) => {
    const { colors, isMobile, isTablet, isDesktop } = useTheme();

    const [viewType, setViewType] = useState(defaultView);
    const [page, setPage] = useState(0);
    const [numberOfItemsPerPageList] = useState([10, 25, 50, 100, 200]);
    const [itemsPerPage, onItemsPerPageChange] = useState(
      numberOfItemsPerPageList[0]
    );
    const [searchQuery, setSearchQuery] = useState("");
    const [showNewDocDialog, setShowNewDocDialog] = useState(false);
    const [showQueryDialog, setShowQueryDialog] = useState(false);

    const [query, setQuery] = useState({
      page: 1,
      perPage: 10,
      sort: defaultSort,
    });

    const { loading, error, data, refetch } = useQuery(gql.Pagination, {
      variables: query,
      initialData: {
        results: {
          count: 0,
          items: [],
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
            itemCount: 0,
            pageCount: 0,
          },
        },
      },
    });

    useLayoutEffect(() => {
      navigation.setOptions({
        header: ({ navigation, scene, previous }) => {
          return (
            <Appbar.Header>
              {previous ? (
                <Appbar.BackAction onPress={() => navigation.goBack()} />
              ) : navigation.openDrawer ? (
                <Appbar.Action
                  icon="menu"
                  onPress={() => navigation.openDrawer()}
                />
              ) : null}
              <Appbar.Action
                icon="plus"
                onPress={() => {
                  setShowNewDocDialog(true);
                }}
              />
              <Appbar.Content title={scene.descriptor.options.title} />
              {isDesktop && (
                <Searchbar
                  placeholder="Search"
                  onChangeText={setSearchQuery}
                  value={searchQuery}
                />
              )}
              <Appbar.Action
                icon="table-search"
                onPress={() => {
                  setShowQueryDialog(true);
                }}
              />
            </Appbar.Header>
          );
        },
      });
    }, [searchQuery]);

    const _refetch = () => {
      if (searchQuery.length) {
        query.search = searchQuery;
      }
      refetch({ ...query, page: page + 1, perPage: itemsPerPage });
    };

    useEffect(() => {
      _refetch();
    }, [query, page, itemsPerPage, searchQuery]);

    const items = data?.results?.items;
    const pageInfo = data?.results?.pageInfo;

    const from = page * itemsPerPage;
    const to = from + pageInfo?.pageCount;

    const search = (query) => {
      console.log("receive query", query);
      setQuery(query);
    };

    return (
      <View
        style={{
          flex: 1,
          ...(Platform.OS === "web" && {
            position: "absolute",
            width: "100%",
            height: "100%",
          }),
        }}
      >
        <View style={{ flex: 1 }}>
          {!isDesktop && (
            <Searchbar
              placeholder="Search"
              onChangeText={setSearchQuery}
              value={searchQuery}
            />
          )}
          <View style={{ flexDirection: "row", paddingRight: 32 }}>
            {loading || error ? (
              <Text>Loading</Text>
            ) : (
              <DataTable.Pagination
                page={page}
                numberOfPages={Math.ceil(pageInfo.itemCount / itemsPerPage)}
                onPageChange={(page) => setPage(page)}
                label={`${from + 1}-${to} of ${pageInfo.itemCount}`}
                numberOfItemsPerPageList={numberOfItemsPerPageList}
                numberOfItemsPerPage={itemsPerPage}
                onItemsPerPageChange={onItemsPerPageChange}
                showFastPaginationControls
                selectPageDropdownLabel={"Per page"}
                style={{
                  justifyContent: "flex-start",
                  flex: 1,
                }}
              />
            )}
            {TableView && ListView && (
              <ToggleButton.Row
                style={{ alignItems: "center" }}
                value={viewType}
                onValueChange={(v) => setViewType(v || viewType)}
              >
                <ToggleButton icon="table" value="table" />
                <ToggleButton icon="format-list-bulleted-square" value="list" />
              </ToggleButton.Row>
            )}
          </View>
          {TableView && viewType === "table" && (
            <TableView loading={loading} error={error} items={items} />
          )}
          {ListView && viewType === "list" && (
            <ListView loading={loading} error={error} items={items} />
          )}
          {NewDialog && (
            <NewDialog
              visible={showNewDocDialog}
              close={() => setShowNewDocDialog(false)}
              refetch={_refetch}
            />
          )}
          {QueryDialog && (
            <QueryDialog
              visible={showQueryDialog}
              close={() => setShowQueryDialog(false)}
              query={query}
              search={search}
            />
          )}
        </View>
      </View>
    );
  };
  SearchTable.title = title;
  return SearchTable;
};

export default GererateSearchTable;
