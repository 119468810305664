import { gql } from "@apollo/client";

export const MsgTemplateManyForSelect = gql`
  query MsgTemplateManyForSelect {
    msgTemplateMany(filter: { type: Email }) {
      _id
      name
    }
  }
`;

export const RenderTemplate = gql`
  query ($project_id: MongoID!, $template_id: MongoID!) {
    message: msgThreadRenderWith(
      docModel: "Project"
      doc_id: $project_id
      template_id: $template_id
    ) {
      title
      body
    }
  }
`;

export const createDraftThread = gql`
  mutation (
    $project_id: MongoID!
    $template_id: MongoID
    $from: String
    $to: [String]
    $title: String
    $content: String
  ) {
    result: msgThreadDraft(
      docModel: "Project"
      doc_id: $project_id
      template_id: $template_id
      from: $from
      to: $to
      subject: $title
      html: $content
    ) {
      _id
      type
      status
      draft {
        template_id
        forward
        message {
          from
          to
          subject
          html
        }
      }
    }
  }
`;

export const getDraftedThread = gql`
  query GetDraftedThread($_id: MongoID!) {
    result: msgThreadById(_id: $_id) {
      template_id
      type
      status
      scheduledAt
      sentAt
      gotreplyAt
      forwardedAt
      updatedAt
      createdAt
      draft {
        template_id
        forward
        message {
          from
          to
          subject
          html
        }
      }
    }
  }
`;

export const updateDraftedThread = gql`
  mutation (
    $_id: MongoID!
    $template_id: MongoID
    $forward: Boolean
    $from: String
    $to: [String]
    $title: String
    $content: String
  ) {
    result: msgThreadDraftUpdate(
      _id: $_id
      template_id: $template_id
      forward: $forward
      from: $from
      to: $to
      subject: $title
      html: $content
    ) {
      draft {
        template_id
        forward
        message {
          from
          to
          subject
          html
        }
      }
    }
  }
`;

export const scheduleDraftedThread = gql`
  mutation ($_id: MongoID!) {
    result: msgThreadDraftSchedule(_id: $_id)
  }
`;
