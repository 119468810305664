// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("typeface-roboto/files/roboto-latin-100.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("typeface-roboto/files/roboto-latin-100.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("typeface-roboto/files/roboto-latin-300.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("typeface-roboto/files/roboto-latin-300.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("typeface-roboto/files/roboto-latin-400.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("typeface-roboto/files/roboto-latin-400.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("typeface-roboto/files/roboto-latin-500.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("typeface-roboto/files/roboto-latin-500.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Roboto-Thin';\n    font-style: normal;\n    font-display: swap;\n    font-weight: 100;\n    src:\n        local('Roboto Thin '),\n        local('Roboto-Thin'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'), \n        url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'); /* Modern Browsers */\n}\n  \n@font-face {\n    font-family: 'Roboto-Light';\n    font-style: normal;\n    font-display: swap;\n    font-weight: 300;\n    src:\n        local('Roboto Light '),\n        local('Roboto-Light'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'), \n        url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff'); /* Modern Browsers */\n}\n  \n@font-face {\n    font-family: 'Roboto';\n    font-style: normal;\n    font-display: swap;\n    font-weight: 400;\n    src:\n        local('Roboto Regular '),\n        local('Roboto-Regular'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'), \n        url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff'); /* Modern Browsers */\n}\n  \n@font-face {\n    font-family: 'Roboto-Medium';\n    font-style: normal;\n    font-display: swap;\n    font-weight: 500;\n    src:\n        local('Roboto Medium '),\n        local('Roboto-Medium'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'), \n        url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff'); /* Modern Browsers */\n}", ""]);
// Exports
module.exports = exports;
