import React, { useMemo, useState } from "react";
import { ScrollView } from "react-native";
import { Button, Portal, Dialog } from "react-native-paper";

import {} from "react-native-paper";

import _ from "lodash";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

const SearchQueryDialog = (props2) => {
  const { RenderSchema, gql, title, RenderFormContent } = props2;
  const Child = (props1) => {
    // console.log("SC2", Object.keys(props2));
    // console.log("SC1", Object.keys(props1));
    // SC2 (7) ['visible', 'close', 'query', 'search', 'title', 'RenderFormContent', 'gql']
    // SC1 (3) ['initForm', 'schema', 'extented']
    const {
      // Required
      visible,
      close,
      query,
      search,
      // Design to be changed
      initForm,
      schema,
      extented,
    } = props1;

    const { control, handleSubmit, reset } = useForm({
      resolver: joiResolver(schema),
      mode: "onTouched",
      defaultValues: initForm,
    });

    const cleanQuery = (input) => {
      const data = _.cloneDeep(input);
      Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof value !== "object") return;

        if (key === "active") {
          let trick = JSON.stringify(value);
          trick = trick
            .replace(/\"T\"/g, "true")
            .replace(/\"F\"/g, "false")
            .replace(/\"N\"/g, "null");
          value = JSON.parse(trick);
        }

        if (!value.op) delete data[key];
        else if (value.op === "bte") {
          data[key] = {
            gte: value.gte,
            lte: value.lte,
            op: value.op,
          };
        } else if (
          [
            "eq",
            "ne",
            "lte",
            "gte",
            "in",
            "nin",
            "contain",
            "notcontain",
            "regex",
          ].includes(value.op)
        ) {
          data[key] = {
            [value.op]: value[value.op],
            op: value.op,
          };
        } else {
          console.log("Unknown Op", value.op);
          throw new Error("Unknown Op");
        }
      });
      return data;
    };

    const toQuery = (input) => {
      const data = _.cloneDeep(input);
      let query = { page: 1, perPage: 10, sort: "SID_DESC" };
      if (data.sortField) {
        query.sort = `${data.sortField}_${data.sortAscending ? "ASC" : "DESC"}`;
        delete data.sortField;
        delete data.sortAscending;
      }
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (!value?.op) {
          query[key] = value;
        } else if (value.op === "eq") {
          query[key] = value.eq;
        } else if (
          ["gt", "gte", "lt", "lte", "ne", "in", "nin"].includes(value.op)
        ) {
          query[`${key}_Op`] = { [value.op]: value[value.op] };
        } else if (["bte"].includes(value.op)) {
          query[`${key}_Op`] = { gte: value.gte, lte: value.lte };
        } else if (["contain"].includes(value.op)) {
          query[`${key}_Op`] = { regex: `/${value.contain}/i` };
        } else if (["notcontain"].includes(value.op)) {
          query[`${key}_Op`] = {
            regex: `/^((?!${value.notcontain}).)*$/i`,
          };
        } else if (["regex"].includes(value.op)) {
          query[`${key}_Op`] = {
            regex: `/${value.regex}/i`,
          };
        }
      });

      return query;
    };

    const onSubmit = (data) => {
      // console.log("onSubmit", data);
      const cleanData = cleanQuery(data);
      // console.log("cleanData", cleanData);
      const query = toQuery(cleanData);
      // console.log("query", query);
      search(query);
      dismiss();
    };
    const onError = (data) => {
      console.log("onError", data);
    };

    const dismiss = () => {
      // reset();
      close();
    };

    return (
      <Portal>
        <Dialog
          onDismiss={dismiss}
          visible={visible}
          dismissable={true}
          style={{ maxWidth: 600, maxHeight: "80%" }}
        >
          <Dialog.Title>Search Filter</Dialog.Title>
          <Dialog.ScrollArea
            style={{ maxHeight: "100%", paddingHorizontal: 0 }}
          >
            <ScrollView>
              <RenderFormContent extented={extented} control={control} />
            </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions>
            <Button onPress={dismiss}>Cancel</Button>
            <Button onPress={handleSubmit(onSubmit, onError)}>Ok</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    );
  };

  return (props1) => {
    return <RenderSchema gql={gql} RenderChild={Child} ChildProps={props1} />;
  };
};

export default SearchQueryDialog;
