import * as React from "react";
//* Template *//
import GenerateSearchTable from "../../Components/SearchTable/GenerateSearchTable.js";
import ListView from "../../Components/SearchTable/ListView";
import NewDialog from "../../Components/SearchTable/NewDialog";
import SearchQueryDialog from "../../Components/SearchTable/SearchQueryDialog";
//* Customs *//
import * as gql from "./gql";
import ViewListRowItem from "./ViewListRowItem.js";
import ViewListItemEdit from "./ViewListItemEdit.js";
import NewFormSchema from "./NewFormSchema.js";
import NewFormContent from "./NewFormContent.js";
import SearchQuerySchema from "./SearchQuerySchema.js";
import SearchFormContent from "./SearchQueryContent.js";

export default GenerateSearchTable({
  title: "Message Templates Table",
  gql: gql,
  NewDialog: NewDialog({
    title: "New Message Template",
    gql: gql,
    RenderSchema: NewFormSchema,
    RenderFormContent: NewFormContent,
  }),

  QueryDialog: SearchQueryDialog({
    title: "Search for Message Templates",
    gql: gql,
    RenderSchema: SearchQuerySchema,
    RenderFormContent: SearchFormContent,
  }),
  // TableView: ViewTable,
  // ListView: ListView({
  //   gql,
  //   ViewListRowItem: ViewListRowItem,
  //   ViewListItemEdit: ViewListItemEdit,
  // }),
  ListView: (props) => (
    <ListView
      gql={gql}
      ViewListRowItem={ViewListRowItem}
      ViewListItemEdit={ViewListItemEdit}
      {...props}
    />
  ),
  defaultView: "list",
  defaultSort: "TID_DESC",
});
