import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  Linking,
  Platform,
  Image,
  View,
  ScrollView,
  SafeAreaView,
} from "react-native";

import {
  Paragraph,
  Colors,
  Portal,
  Dialog,
  useTheme,
  Switch,
  HelperText,
  Button,
  Menu,
  Divider,
  Provider,
  Text,
} from "react-native-paper";

import { useForm, Controller } from "react-hook-form";

import SmartFormRadioGroup from "./SmartFormRadioGroup";
import SmartFormCheckboxGroup from "./SmartFormCheckboxGroup";
import MenuOperators from "./SmartFormMenuOperators";
import { Options, Option } from "./SmartFormOptions";

import Toggle from "../Input/Toggle";

// const Toggle = ({
//   value,
//   onChange,
//   invalid,
//   error,
//   customError,
// }: {
//   value: any;
//   onChange: (...event: any[]) => void;
//   invalid: boolean;
//   error: any | undefined;
//   customError: string | undefined;
// }) => {
//   return (
//     <View>
//       <Switch value={value} onValueChange={() => onChange(!value)} />
//       <HelperText type="error" padding="none" visible={invalid}>
//         {customError || error?.message}
//       </HelperText>
//     </View>
//   );
// };

const SmartFormToggle = ({
  visible,
  title,
  name,
  control,
  isQuery,
  customError,
}: {
  visible?: boolean | undefined;
  title?: string;
  name?: string;
  control?: any | undefined;
  isQuery?: boolean | undefined;
  customError?: string;
}) => {
  if (visible === false) return null;
  const operators = [
    { name: "Not set", value: "" },
    { name: "Equal", value: "eq" },
    { name: "Not equal", value: "ne" },
    { name: "In...", value: "in" },
    { name: "Not in...", value: "nin" },
  ];
  // const [operator, setOperator] = useState(operators[0].value);

  const options: Options = [
    { name: "True", value: "T" },
    { name: "False", value: "F" },
    { name: "Null", value: "N" },
  ];

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <View style={styles.row}>
          <View>
            <MenuOperators
              title={title}
              visible={isQuery}
              operators={operators}
              operator={value?.op}
              setOperator={(v) => {
                onChange({ ...value, op: v });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              marginHorizontal: 16,
            }}
          >
            {(() => {
              if (isQuery) {
                if (!value?.op) {
                  return <Text>n/a</Text>;
                } else if (value?.op === "eq" || value?.op === "ne") {
                  return (
                    <SmartFormRadioGroup
                      value={value}
                      onChange={onChange}
                      options={options}
                      error={error}
                      customError={customError}
                      operator={value?.op}
                    />
                  );
                } else if (value?.op === "in" || value?.op === "nin") {
                  return (
                    <SmartFormCheckboxGroup
                      value={value}
                      onChange={onChange}
                      options={options}
                      error={error}
                      customError={customError}
                      invalid={invalid}
                      operator={value?.op}
                    />
                  );
                }
              }
              return (
                <Toggle
                  value={value}
                  onChange={onChange}
                  invalid={invalid}
                  error={error}
                  customError={customError}
                />
              );
            })()}
          </View>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "space-between",
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
});

export default SmartFormToggle;
