import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { View, Dimensions, Platform } from "react-native";
import "fast-text-encoding";
import _Joi from "joi";

const Joi = _Joi.extend((joi) => ({
  base: joi.array(),
  coerce: (value, helpers) => ({
    value: value.split
      ? value
          .replace(/^,+|,+$/gm, "")
          .split(",")
          .map((v) => v.trim())
      : value,
  }),
  type: "emailSplit",
}));

import {
  SmartFormToggle,
  SmartFormTextInput,
  SmartFormDatePicker,
  SmartFormRadioGroup,
  SmartFormTextArea,
  SmartFormCheckboxGroup,
  SmartFormNumberInput,
  SmartFormRichTextEditor,
  SmartFormRichTextEditorWeb,
} from "../../Components/SmartForm";

import _ from "lodash";

import dot from "dot-object";

const ViewListItemEdit = forwardRef((props, ref) => {
  const { gql, item } = props;
  const {
    loading: EnumProjectSourceLoading,
    error: EnumProjectSourceError,
    data: EnumProjectSourceData,
  } = useQuery(gql.EnumProjectSource);

  const initForm = useMemo(() => {
    const toOmitFromEdit = [
      "_id",
      "createdAt",
      "lastCrawled",
      "manualInput",
      "sid",
      "updatedAt",
      "goal",
      "emailcrawler",
      "contact_tasks",
    ];
    return _(item).omitGqlPropsDeep().omit(toOmitFromEdit).value();
  }, []);

  const schema = Joi.object({
    active: Joi.boolean(),
    date: Joi.any(),
    source: Joi.string().required(),
    slug: Joi.any(),
    photo: Joi.string().uri().required(),
    name: Joi.any(),
    pledged_usd: Joi.number(),
    pledged_persentage: Joi.number(),
    backers_count: Joi.number(),
    creator: Joi.any(),
    category: Joi.any(),
    link: Joi.string().uri().required(),
    keywords: Joi.any(),
    notes: Joi.string().empty("").max(2000),
    contact: {
      emails: Joi.emailSplit()
        .items(
          Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } })
        )
        .single(),
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      submitCount,
      dirtyFields,
    },
  } = useForm({
    resolver: joiResolver(schema),
    mode: "onTouched",
    defaultValues: initForm,
  });

  useImperativeHandle(ref, () => ({
    submit: ({ onSubmit, onError }) =>
      handleSubmit((data) => {
        console.log("child handle");
        const dotDirtyFields = Object.keys(dot.dot(dirtyFields));
        const update = _.pick(data, dotDirtyFields);
        onSubmit(update);
      }, onError)(),
    reset,
  }));

  return (
    <>
      <SmartFormToggle
        title="Active"
        name="active"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormDatePicker
        title="Date"
        name="date"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormRadioGroup
        title="Source"
        name="source"
        control={control}
        options={
          EnumProjectSourceLoading || EnumProjectSourceError
            ? []
            : EnumProjectSourceData?.__type?.enumValues.map((v) => ({
                name: v.name,
                value: v.name,
              }))
        }
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Slug"
        name="slug"
        placeholder="Enter URL Slug"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Photo"
        name="photo"
        placeholder="Enter Link for the photo"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Name"
        name="name"
        placeholder="Enter Project Name"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Pledged USD"
        name="pledged_usd"
        placeholder="Enter pledged amount in USD"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Pledged %"
        name="pledged_persentage"
        placeholder="Enter the progress of goal"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Backers Count"
        name="backers_count"
        placeholder="Enter the Number of backers"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Creator"
        name="creator"
        placeholder="Enter name of the project creator"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Category"
        name="category"
        placeholder="Enter Category for the project"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Link"
        name="link"
        placeholder="Enter Link for the project"
        control={control}
        visible={item.manualInput}
      />
      <SmartFormTextInput
        title="Keywords"
        name="keywords"
        placeholder="Enter Keywords for this project, seperate by (,)comma"
        control={control}
      />
      <SmartFormTextInput
        title="ContactEmails"
        name="contact.emails"
        placeholder="Enter Email to send them newsletters, seperate by (,)comma"
        control={control}
      />
      <SmartFormTextArea
        title="Notes"
        name="notes"
        placeholder="Type something"
        numberOfLines={5}
        control={control}
      />
    </>
  );
});

export default ViewListItemEdit;
