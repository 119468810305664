import React, { useState, useMemo, useEffect, useCallback } from "react";
import { View, ScrollView } from "react-native";
import { Button, useTheme, Portal, Dialog, Text } from "react-native-paper";

import { DialogAlert, DialogLoading } from "../Dialog";

import { useResettableMutation } from "apollo-hooks-extended";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

const NewDialog = (props2) => {
  const { RenderSchema, gql, title, RenderFormContent } = props2;
  const Child = (props1) => {
    // console.log("NC1", Object.keys(props1));
    const {
      // Required
      visible,
      close,
      refetch,
      // Design to changed
      initForm,
      schema,
      postFormVariables,
      extented,
    } = props1;

    const { colors } = useTheme();

    // console.log("NC Visiable", visible);
    // const [showDialog, setShowDialog] = useState(visible);

    const {
      control,
      handleSubmit,
      reset,
      formState: { errors, isDirty, isSubmitting, touchedFields, submitCount },
    } = useForm({
      resolver: joiResolver(schema),
      mode: "onTouched",
      defaultValues: initForm,
    });
    const [
      PostOneFn,
      {
        data: PostOneData,
        loading: PostOneLoading,
        error: PostOneError,
        reset: PostOneReset,
      },
    ] = useResettableMutation(gql.PostOne, {
      onCompleted: (data) => {
        console.log("Completed!");
        refetch();
        dismiss();
      },
      onError: (e) => {
        console.log("error", e);
      },
    });

    const [formError, setFormError] = useState(null);

    const onError = (errors, event) => {
      let message = Object.keys(errors)
        .map((v) => `${v}: ${errors[v].message}`)
        .map((v) => <Text style={{ color: colors.surface }}>{v}</Text>);

      setFormError(message);
    };

    const onSubmit = (data) => {
      console.log("onSubmit", data);
      PostOneFn({
        variables: postFormVariables(data),
      });
    };

    // const dismiss = useCallback(() => {
    //   reset();
    //   setShowDialog(false);
    //   close();
    // }, [close]);

    const dismiss = () => {
      reset();
      // setShowDialog(false);
      close();
    };

    if (PostOneError) {
      return (
        <DialogAlert
          visible
          title="Something Wrong"
          close={() => PostOneReset()}
        >
          {PostOneError.message}
          {JSON.stringify(PostOneError?.networkError?.result)}
        </DialogAlert>
      );
    }

    if (PostOneLoading) {
      return (
        <DialogLoading title="Creating new Doc" close={() => PostOneReset()}>
          Please Wait
        </DialogLoading>
      );
    }

    if (formError) {
      return (
        <DialogAlert
          visible
          title="Form validation error"
          close={() => setFormError(null)}
        >
          <View style={{ flexDirection: "column" }}>{formError}</View>
        </DialogAlert>
      );
    }
    return (
      <Portal>
        <Dialog
          onDismiss={dismiss}
          visible={visible}
          dismissable={true}
          style={{ maxHeight: "90%" }}
        >
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.ScrollArea
            style={{ maxHeight: "100%", paddingHorizontal: 0 }}
          >
            <ScrollView>
              <RenderFormContent extented={extented} control={control} />
            </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions>
            <Button onPress={dismiss}>Cancel</Button>
            <Button onPress={handleSubmit(onSubmit, onError)}>Ok</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    );
  };

  return (props1) => {
    return <RenderSchema gql={gql} RenderChild={Child} ChildProps={props1} />;
  };
};

export default NewDialog;
