import * as React from "react";
import { View, Platform, Dimensions } from "react-native";
import { Appbar } from "react-native-paper";
import { createStackNavigator } from "@react-navigation/stack";
import ExampleList, { examples } from "./ExampleList";
import ProjectSearchTable from "./Page/Projects/ProjectSearchTable";
import MessageTemplateSearchTable from "./Page/MessageTemplates/MessageTemplateSearchTable";
import SettingsList from "./Page/Settings/SettingsList";
import EmailThreadCompose from "./Page/EmailThread/Compose";

const Stack = createStackNavigator();
export default function Root() {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={{
        header: ({ navigation, scene, previous }) => {
          return (
            <Appbar.Header>
              {previous ? (
                <Appbar.BackAction onPress={() => navigation.goBack()} />
              ) : navigation.openDrawer ? (
                <Appbar.Action
                  icon="menu"
                  onPress={() => navigation.openDrawer()}
                />
              ) : null}
              <Appbar.Content title={scene.descriptor.options.title} />
            </Appbar.Header>
          );
        },
      }}
    >
      <Stack.Screen
        name="Projects"
        component={ProjectSearchTable}
        options={{ title: "Projects" }}
      />
      <Stack.Screen
        name="Message Templates"
        component={MessageTemplateSearchTable}
        options={{ title: "Message Templates" }}
      />
      <Stack.Screen
        name="Settings"
        component={SettingsList}
        options={{ title: "Settings" }}
      />
      <Stack.Screen
        name="Examples"
        component={ExampleList}
        options={{ title: "Examples" }}
      />
      {Object.keys(examples).map((id) => (
        <Stack.Screen
          key={id}
          name={id}
          component={examples[id]}
          options={{ title: examples[id].title }}
        />
      ))}
      <Stack.Screen
        name="EmailThreadCompose"
        component={EmailThreadCompose}
        options={{ title: "Email Thread Compose" }}
      />
    </Stack.Navigator>
  );
}
