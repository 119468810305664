/**
 * @author tangzehua
 * @since 2020-07-09 16:02
 */
import React from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";

const FontSizeSelect = (props) => {
  const { onSelect } = props;
  // 1=  10px, 2 = 13px, 3 = 16px, 4 = 18px, 5 = 24px, 6 = 32px, 7 = 48px;
  return (
    <View style={styles.view}>
      <Text style={styles.item} onPress={() => onSelect(1)}>
        10px
      </Text>
      <Text style={styles.item} onPress={() => onSelect(2)}>
        13px
      </Text>
      <Text style={styles.item} onPress={() => onSelect(3)}>
        16px
      </Text>
      <Text style={styles.item} onPress={() => onSelect(4)}>
        18px
      </Text>
      <Text style={styles.item} onPress={() => onSelect(5)}>
        24px
      </Text>
      <Text style={styles.item} onPress={() => onSelect(6)}>
        32px
      </Text>
      <Text style={styles.item} onPress={() => onSelect(7)}>
        48px
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  view: {
    alignSelf: "center",
    flexWrap: "wrap",
    flexDirection: "row",
    width: Math.min(Dimensions.get("window").width, 32 * 12),
  },
  item: {
    // height: 25,
    // width: 25,
    fontSize: 20,
    paddingHorizontal: 3,
    paddingVertical: 5,
  },
});

export default FontSizeSelect;
