import { gql } from "@apollo/client";

export const Pagination_old = gql`
  query pagination(
    $search: String
    $type: EnumMsgTemplateType
    $name: String
    $title: String
    $body: String
    $stats_sent: Float
    $stats_draft: Float
    $stats_feedback: Float
    $tid: Float
    $type_Op: FilterFindManyMsgTemplateTypeOperatorsInput
    $name_Op: FilterFindManyMsgTemplateNameOperatorsInput
    $title_Op: FilterFindManyMsgTemplateTitleOperatorsInput
    $body_Op: FilterFindManyMsgTemplateBodyOperatorsInput
    $stats_sent_Op: FilterFindManyMsgTemplateStatsSentOperatorsInput
    $stats_draft_Op: FilterFindManyMsgTemplateStatsDraftOperatorsInput
    $stats_feedback_Op: FilterFindManyMsgTemplateStatsFeedbackOperatorsInput
    $tid_Op: FilterFindManyMsgTemplateTidOperatorsInput
    $page: Int
    $perPage: Int
    $sort: SortFindManyMsgTemplateInput
  ) {
    msgTemplatePagination(
      filter: {
        search: $search
        type: $type
        name: $name
        title: $title
        body: $body
        stats: {
          sent: $stats_sent
          draft: $stats_draft
          feedback: $stats_feedback
        }
        tid: $tid
        _operators: {
          type: $type_Op
          name: $name_Op
          title: $title_Op
          body: $body_Op
          stats: {
            sent: $stats_sent_Op
            draft: $stats_draft_Op
            feedback: $stats_feedback_Op
          }
          tid: $tid_Op
        }
      }
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      count
      items {
        _id
        tid
        type
        name
        title
        body
        stats {
          draft
          sent
          feedback
        }
        updatedAt
        createdAt
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const Pagination = gql`
  query pagination(
    $search: String
    $type: EnumMsgTemplateType
    $name: String
    $title: String
    $body: String
    $stats: FilterFindManyMsgTemplateStatsInput
    $tid: Float
    $type_Op: FilterFindManyMsgTemplateTypeOperatorsInput
    $name_Op: FilterFindManyMsgTemplateNameOperatorsInput
    $title_Op: FilterFindManyMsgTemplateTitleOperatorsInput
    $body_Op: FilterFindManyMsgTemplateBodyOperatorsInput
    $stats_Op: FilterFindManyMsgTemplateStatsOperatorsInput
    $tid_Op: FilterFindManyMsgTemplateTidOperatorsInput
    $page: Int
    $perPage: Int
    $sort: SortFindManyMsgTemplateInput
  ) {
    results: msgTemplatePagination(
      filter: {
        search: $search
        type: $type
        name: $name
        title: $title
        body: $body
        stats: $stats
        tid: $tid
        _operators: {
          type: $type_Op
          name: $name_Op
          title: $title_Op
          body: $body_Op
          stats: $stats_Op
          tid: $tid_Op
        }
      }
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      count
      items {
        _id
        tid
        type
        name
        title
        body
        stats {
          draft
          sent
          feedback
        }
        updatedAt
        createdAt
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const EnumSortBy = gql`
  query EnumSortBy {
    __type(name: "SortFindManyMsgTemplateInput") {
      enumValues {
        name
      }
    }
  }
`;
export const GetById = gql`
  query GetById($_id: MongoID!) {
    result: msgTemplateById(_id: $_id) {
      _id
      tid
      type
      name
      title
      body
      stats {
        draft
        sent
        feedback
      }
      updatedAt
      createdAt
    }
  }
`;

export const PostOne = gql`
  mutation msgTemplateCreateOne(
    $type: EnumMsgTemplateType
    $name: String
    $title: String
    $body: String
  ) {
    msgTemplateCreateOne(
      record: { type: $type, name: $name, title: $title, body: $body }
    ) {
      recordId
      error {
        message
      }
    }
  }
`;

export const UpdateById = gql`
  mutation ($_id: MongoID!, $update: UpdateByIdMsgTemplateInput!) {
    msgTemplateUpdateById(_id: $_id, record: $update) {
      recordId
      error {
        message
      }
    }
  }
`;

export const DeleteById = gql`
  mutation DeleteById($_id: MongoID!) {
    result: msgTemplateDeleteById(_id: $_id) {
      recordId
      error {
        message
      }
    }
  }
`;

// Customs
export const EnumMsgTemplateType = gql`
  query EnumMsgTemplateType {
    __type(name: "EnumMsgTemplateType") {
      enumValues {
        name
      }
    }
  }
`;
