import React, { useMemo } from "react";
import {
  SmartFormToggle,
  SmartFormTextInput,
  SmartFormDatePicker,
  SmartFormRadioGroup,
  SmartFormTextArea,
  SmartFormCheckboxGroup,
  SmartFormNumberInput,
} from "../../Components/SmartForm";

const SearchFormContent = ({ control, extented }) => {
  const {
    EnumProjectSourceLoading,
    EnumProjectSourceError,
    EnumProjectSourceData,
    EnumSortByValue,
  } = extented;

  //* TODO *//
  const sortableFields = useMemo(
    () => EnumSortByValue.map((v) => ({ name: v, value: v })),
    []
  );

  const ProjectSourceOptions = useMemo(
    () =>
      EnumProjectSourceLoading || EnumProjectSourceError
        ? []
        : EnumProjectSourceData?.__type?.enumValues.map((v) => ({
            name: v.name,
            value: v.name,
          })),
    [EnumProjectSourceData]
  );
  return (
    <>
      <SmartFormNumberInput
        title="Sid"
        name="sid"
        placeholder="sid"
        control={control}
        isQuery
        min={0}
      />
      <SmartFormDatePicker title="Date" name="date" control={control} isQuery />
      <SmartFormToggle
        title="State Active"
        name="active"
        control={control}
        isQuery
      />
      <SmartFormDatePicker
        title="LastCrawled"
        name="lastCrawled"
        control={control}
        isQuery
      />

      <SmartFormRadioGroup
        title="Source Test"
        name="source"
        control={control}
        isQuery
        options={ProjectSourceOptions}
      />
      <SmartFormTextInput
        title="Slug"
        name="slug"
        placeholder="Enter URL Slug"
        control={control}
        isQuery
      />
      <SmartFormTextInput
        title="Project Name"
        name="name"
        placeholder="Enter Project Name"
        control={control}
        isQuery
      />
      <SmartFormNumberInput
        title="Pledged USD"
        name="pledged_usd"
        placeholder="USD"
        control={control}
        isQuery
        min={0}
      />
      <SmartFormNumberInput
        title="Pledged Progress"
        name="pledged_persentage"
        placeholder="%"
        control={control}
        isQuery
        min={0}
        max={100}
      />
      <SmartFormNumberInput
        title="Backer Count"
        name="backers_count"
        placeholder="Count"
        control={control}
        isQuery
        min={0}
        max={100}
      />
      <SmartFormTextInput
        title="Creator"
        name="creator"
        placeholder="Enter name of the project creator"
        control={control}
        isQuery
      />
      <SmartFormTextInput
        title="Category"
        name="category"
        placeholder="Enter Category for the project"
        control={control}
        isQuery
      />
      <SmartFormTextInput
        title="Link"
        name="link"
        placeholder="Enter Link for the project"
        control={control}
        isQuery
      />
      <SmartFormTextInput
        title="Keywords"
        name="keywords"
        placeholder="Enter keywords"
        control={control}
        isQuery
      />
      <SmartFormTextInput
        title="Notes"
        name="notes"
        placeholder="Enter notes"
        control={control}
        isQuery
      />
      <SmartFormRadioGroup
        title="Sort By"
        name="sortField"
        control={control}
        options={sortableFields}
      />
      <SmartFormToggle
        title="Sort Ascending"
        name="sortAscending"
        control={control}
      />
    </>
  );
};

export default SearchFormContent;
