import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Alert,
  ScrollView,
  StyleSheet,
  View,
  Linking,
  Image,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import {
  DataTable,
  Button,
  TextInput,
  Checkbox,
  useTheme,
  Text,
  Searchbar,
  Card,
  Paragraph,
  Avatar,
  IconButton,
  Menu,
  Divider,
  List,
  Colors,
  Portal,
} from "react-native-paper";
import moment from "moment";
import _, { debounce } from "lodash";
import ImageViewDialog from "../../Components/Dialog/ImageViewDialog";
// import ViewListItemEdit from "./viewListItemEdit";
import { DialogConfirm, DialogAlert } from "../../Components/Dialog";

import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import findByKey from "find-by-key";

const ViewListRowItem = (props) => {
  const { colors } = useTheme();
  const { ViewListItemEdit, gql } = props;
  const [item, setItem] = useState(props.item);

  const [visibleContextMenu, setVisibleContextMenu] = useState(false);
  const [ticked, setTicked] = useState(false);
  const [showImageView, setShowImageView] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [errorDialog, setErrorDialog] = useState(null);
  const editItem = useRef();

  const [getOne] = useLazyQuery(gql.GetById, {
    variables: { _id: item._id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data?.result) return;
      setItem(data.result);
    },
  });

  const [deleteOne] = useMutation(gql.DeleteById, {
    variables: { _id: item._id },
    onCompleted: (data) => {
      if (!data?.result?.recordId) return;
      setItem({});
    },
    onError: (e) => {
      alert("delete " + e);
    },
  });

  const [updateOne] = useMutation(gql.UpdateById, {
    onCompleted: (data) => {
      console.log("Update Completed!", data);
    },
    onError: (e) => {
      alert("updateChanged " + e);
    },
  });

  const doUpdate = useCallback(
    (update) =>
      updateOne({
        variables: { _id: item._id, update },
      }),
    []
  );

  const debounceChange = useCallback(
    debounce(doUpdate, 2000),
    [] // will be created only once initially
  );

  if (!item._id) return null;

  return (
    <>
      <Card
        style={[
          styles.card,
          { backgroundColor: ticked ? Colors.purple50 : Colors.white },
        ]}
        mode="outlined"
      >
        <Card.Title
          title={item.name}
          left={(props) => (
            <Avatar.Text
              {...props}
              style={{
                backgroundColor: colors.primary,
              }}
              size={36}
              label={item.tid}
            />
          )}
          right={(props) => (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Menu
                visible={visibleContextMenu}
                onDismiss={() => setVisibleContextMenu(!visibleContextMenu)}
                anchor={
                  <IconButton
                    {...props}
                    icon="dots-vertical"
                    onPress={() => setVisibleContextMenu(!visibleContextMenu)}
                  />
                }
              >
                <Menu.Item
                  icon="pencil"
                  onPress={() => {
                    setVisibleContextMenu(false);
                    setEditMode(true);
                  }}
                  title="Edit"
                  // disabled={!item.manualInput}
                />
                <Menu.Item
                  icon="trash-can-outline"
                  onPress={() => {
                    setVisibleContextMenu(false);
                    setShowDeleteConfirm(true);
                  }}
                  title="Delete"
                  // disabled={!item.manualInput}
                />
                <Divider />
                <Menu.Item
                  onPress={() => {}}
                  title="Below are dummies"
                  disabled
                />
                <Menu.Item
                  icon="undo"
                  onPress={() => {}}
                  title="Undo"
                  disabled
                />
                <Menu.Item
                  icon="redo"
                  onPress={() => {}}
                  title="Redo"
                  disabled
                />
                <Divider />
                <Menu.Item
                  icon="content-cut"
                  onPress={() => {}}
                  title="Cut"
                  disabled
                />
                <Menu.Item
                  icon="content-copy"
                  onPress={() => {}}
                  title="Copy"
                  disabled
                />
                <Menu.Item
                  icon="content-paste"
                  onPress={() => {}}
                  title="Paste"
                  disabled
                />
              </Menu>
            </View>
          )}
        />
        <Portal.Host>
          <View style={{ flexDirection: "row" }}>
            <View style={[{ flexDirection: "column" }, styles.cover]}>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() => {
                    setShowImageView(true);
                  }}
                >
                  <Image
                    style={{
                      flex: 1,
                      width: undefined,
                      height: undefined,
                      minHeight: 100,
                      resizeMode: "contain",
                    }}
                    source={{ uri: item.photo }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={[{ flexDirection: "column" }, styles.content]}>
              <Card.Content>
                {editMode && ViewListItemEdit ? (
                  <>
                    <ViewListItemEdit item={item} ref={editItem} gql={gql} />
                  </>
                ) : (
                  <>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Type: </Paragraph>
                      <Text style={styles.data}>{item.type}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Title: </Paragraph>
                      <Text style={styles.data}>{item.title}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Body: </Paragraph>
                      <Text style={styles.data}>{item.body}</Text>
                    </View>
                  </>
                )}
              </Card.Content>
            </View>
          </View>
          <Card.Actions>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
              }}
            >
              <Checkbox.Item
                label="Select"
                status={ticked ? "checked" : "unchecked"}
                onPress={() => setTicked(!ticked)}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              {editMode ? (
                <>
                  <Button
                    style={styles.actionButtons}
                    mode="contained"
                    onPress={() => {
                      editItem.current.submit({
                        onSubmit: (data) => {
                          console.log("parent onSubmit");
                          doUpdate(data);
                          editItem.current.reset();
                          setEditMode(false);
                          setTimeout(getOne, 500);
                        },
                        onError: (errors, event) => {
                          console.log("Edit error", errors);
                          let filtered = findByKey(errors, "message").join(
                            "\n"
                          );
                          setErrorDialog({
                            title: "Validation Error",
                            children: filtered,
                          });
                        },
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    style={styles.actionButtons}
                    onPress={() => {
                      editItem.current.reset();
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={styles.actionButtons}
                    icon="refresh"
                    onPress={getOne}
                  >
                    Refresh
                  </Button>
                </>
              )}
            </View>
          </Card.Actions>
          {showDeleteConfirm && (
            <DialogConfirm
              visible={showDeleteConfirm}
              close={() => setShowDeleteConfirm(false)}
              title="Confirm to delete"
              confirm={() => {
                deleteOne();
                setShowDeleteConfirm(false);
              }}
            >
              <Paragraph style={{ color: Colors.white }}>
                Are you sure to delete "{item.name}"
              </Paragraph>
            </DialogConfirm>
          )}
          {errorDialog && (
            <DialogAlert
              visible={errorDialog}
              close={() => setErrorDialog(null)}
              title={errorDialog && errorDialog?.title}
              children={errorDialog && errorDialog?.children}
            />
          )}
        </Portal.Host>
      </Card>
      {/* <ImageViewDialog
        visible={showImageView}
        close={() => setShowImageView(false)}
        title={`Preview - ${item.name}`}
        source={{ uri: item.photo }}
      /> */}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 4,
  },
  card: {
    margin: 12,
  },
  cover: {
    flex: 3,
  },
  content: { flex: 9 },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 0,
    paddingHorizontal: 16,
  },
  label: {
    flex: 2,
  },
  data: {
    flex: 10,
  },
  checkBoxGroup: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionButtons: {
    marginHorizontal: 8,
  },
  surface: {
    padding: 8,
    height: 80,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
    elevation: 4,
  },
});
export default ViewListRowItem;
