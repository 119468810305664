import React, { useState, useEffect, useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Paragraph, HelperText, RadioButton, Text } from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import MenuOperators from "./SmartFormMenuOperators";
import SmartFormCheckboxGroup from "./SmartFormCheckboxGroup";
import { Options, Option } from "./SmartFormOptions";

const Radiogroup = ({
  value,
  onChange,
  options,
  invalid,
  error,
  customError,
  operator,
}) => {
  const _onChange = (data) => {
    if (!operator) onChange(data);
    else {
      onChange({ ...value, [operator]: data });
    }
  };
  const nestedValue = operator ? value[operator] : value;
  return (
    <View style={{ flex: 1 }}>
      <RadioButton.Group value={nestedValue} onValueChange={_onChange}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          {options?.map((v) => (
            <RadioButton.Item
              key={v.value}
              label={v.name}
              value={v.value}
              mode="android"
              style={{
                paddingVertical: 0,
                paddingHorizontal: 0,
              }}
            />
          ))}
        </View>
      </RadioButton.Group>

      <HelperText type="error" padding="none" visible={invalid}>
        {customError || error?.message}
      </HelperText>
    </View>
  );
};

const SmartFormRadioGroup = ({
  visible,
  title,
  name,
  control,
  isQuery,
  customError,
  placeholder,
  options,

  value,
  onChange,
  invalid,
  error,
  operator,
}: {
  visible?: boolean | undefined;
  title?: string | undefined;
  name?: string | undefined;
  control?: any | undefined;
  isQuery?: boolean | undefined;
  customError?: string | undefined;
  placeholder?: string | undefined;
  options: Options | [];

  value?: any | undefined;
  onChange?: (...event: any[]) => void | undefined;
  invalid?: boolean | undefined;
  error?: any | undefined;
  operator?: string | undefined;
}) => {
  if (visible === false) return null;
  const operators = [
    { name: "Not set", value: "" },
    { name: "Equal", value: "eq" },
    { name: "Not equal", value: "ne" },
    { name: "In...", value: "in" },
    { name: "Not in...", value: "nin" },
  ];
  // const [operator, setOperator] = useState(operators[0].value);

  if (!control) {
    return (
      <Radiogroup
        value={value}
        onChange={onChange}
        options={options}
        invalid={invalid}
        error={error}
        operator={operator}
      />
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
          <View key={value?.op} style={styles.row}>
            <View>
              <MenuOperators
                title={title}
                visible={isQuery}
                operators={operators}
                operator={value?.op}
                setOperator={(v) => {
                  onChange({ ...value, op: v });
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                marginHorizontal: 16,
              }}
            >
              {(() => {
                if (isQuery && !value?.op) return <Text>n/a</Text>;
                if (!isQuery || ["eq", "ne"].includes(value?.op)) {
                  return (
                    <Radiogroup
                      value={value}
                      onChange={onChange}
                      options={options}
                      invalid={invalid}
                      error={error}
                      customError={customError}
                      operator={value?.op}
                    />
                  );
                } else if (["in", "nin"].includes(value?.op)) {
                  return (
                    <SmartFormCheckboxGroup
                      value={value}
                      onChange={onChange}
                      options={options}
                      error={error}
                      invalid={invalid}
                      customError={customError}
                      operator={value?.op}
                    />
                  );
                }
              })()}
            </View>
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  dialogInputContainerStyle: {
    marginHorizontal: 8,
  },
});

export default SmartFormRadioGroup;
