import React, { useState, Dispatch } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Menu, Paragraph } from "react-native-paper";

const MenuOperators = ({
  title,
  visible,
  operators,
  operator,
  setOperator,
}: {
  title: string;
  visible: boolean;
  operators: Array<{ name: string; value: any }> | [];
  operator: any;
  setOperator: Dispatch<string>;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const open = () => setShowMenu(true);
  const close = () => setShowMenu(false);

  operator = operator || "";

  if (!visible) return <Paragraph>{title}</Paragraph>;
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "flex-start",
        flexWrap: "wrap",
        width: "auto",
      }}
    >
      <Paragraph>{title}</Paragraph>
      <Menu
        visible={showMenu}
        onDismiss={close}
        anchor={
          <Button
            mode="outlined"
            onPress={open}
            style={styles.buttonText}
            contentStyle={styles.buttonText}
            labelStyle={styles.buttonText}
          >
            {operators.find((v) => v.value === operator).name}
          </Button>
        }
      >
        {operators.map((v) => (
          <Menu.Item
            key={v.value}
            onPress={() => {
              setOperator(v.value);
              close();
            }}
            title={v.name}
          />
        ))}
      </Menu>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonText: { padding: 0, marginVertical: 2, width: "auto" },
});
export default MenuOperators;
