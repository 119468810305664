import React, { useState } from "react";
import { View, ScrollView, Image } from "react-native";
import {
  Button,
  useTheme,
  Portal,
  Dialog,
  Text,
  Colors,
} from "react-native-paper";

const ImageViewDialog = ({ visible, close, title, source }) => {
  const { colors } = useTheme();

  return (
    <Portal>
      <Dialog
        onDismiss={close}
        style={{ backgroundColor: Colors.purple900, height: "90%" }}
        visible={visible}
      >
        <Dialog.Title style={{ color: Colors.white }}>{title}</Dialog.Title>
        <Dialog.Content style={{ flex: 1 }}>
          <Image
            style={{
              flex: 1,
              width: undefined,
              height: undefined,
              resizeMode: "contain",
            }}
            source={source}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button color={Colors.white} onPress={close}>
            Close
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default ImageViewDialog;
