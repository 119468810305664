import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  Linking,
  Platform,
  Image,
  View,
  ScrollView,
  SafeAreaView,
} from "react-native";

import {
  Paragraph,
  Colors,
  Portal,
  Dialog,
  useTheme,
  Switch,
  TextInput,
  HelperText,
  Text,
} from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import MenuOperators from "./SmartFormMenuOperators";

const SimpleNumber = ({
  title,
  placeholder,
  onBlur,
  onChange,
  value,
  invalid,
  customError,
  error,
  operator,
  min,
  max,
}) => {
  let nestedValue = operator ? value?.[operator] || null : value;
  let [localVal, setLocalVal] = useState(nestedValue);
  let _onChange = (data) => {
    data = data.replace(/\D/g, "");
    let numData = +data;
    let valid = true;
    if (data !== "" && !Number.isNaN(data)) {
      if (typeof min !== "undefined" && numData < min) {
        valid = false;
      } else if (typeof max !== "undefined" && numData > max) {
        valid = false;
      }
    }

    if (!valid) return;
    if (!operator) onChange(data);
    else {
      onChange({ ...value, [operator]: data });
    }
    setLocalVal(data);
  };
  return (
    <View style={{ flex: 1 }}>
      <TextInput
        label={title}
        style={{
          backgroundColor: "transparent",
          paddingHorizontal: 0,
        }}
        placeholder={placeholder}
        onBlur={onBlur}
        onChangeText={_onChange}
        value={localVal}
      />
      <HelperText type="error" padding="none" visible={invalid}>
        {customError || error?.message}
      </HelperText>
    </View>
  );
};

const SmartFormNumberInput = ({
  visible,
  title,
  name,
  control,
  customError,
  placeholder,
  isQuery,
  min,
  max,
}: {
  visible?: boolean | undefined;
  title?: string;
  name?: string;
  control?: any | undefined;
  customError?: string;
  placeholder?: string;
  isQuery?: boolean | undefined;
  min?: number | undefined;
  max?: number | undefined;
}) => {
  if (visible === false) return null;
  const operators = [
    { name: "Not set", value: "" },
    { name: "Equal", value: "eq" },
    { name: "Not equal", value: "ne" },
    { name: "Greater...", value: "gte" },
    { name: "Lesser...", value: "lte" },
    { name: "Between", value: "bte" },
  ];
  // const [operator, setOperator] = useState(operators[0].value);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <View style={styles.row}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <MenuOperators
              title={title}
              visible={isQuery}
              operators={operators}
              operator={value?.op}
              setOperator={(v) => {
                onChange({ ...value, op: v });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              marginHorizontal: 16,
            }}
          >
            {(() => {
              if (isQuery && !value?.op) return <Text>n/a</Text>;
              if (!isQuery || ["eq", "ne", "gte", "lte"].includes(value?.op)) {
                return (
                  <SimpleNumber
                    // title={title}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    invalid={invalid}
                    customError={customError}
                    error={error}
                    operator={value?.op}
                    min={min}
                    max={max}
                  />
                );
              } else if (value?.op === "bte") {
                return (
                  <>
                    <SimpleNumber
                      title="From"
                      placeholder={placeholder}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      invalid={invalid}
                      customError={customError}
                      error={error}
                      operator="gte"
                      min={min}
                      max={max}
                    />
                    <SimpleNumber
                      title="To"
                      placeholder={placeholder}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      invalid={invalid}
                      customError={customError}
                      error={error}
                      operator="lte"
                      min={min}
                      max={max}
                    />
                  </>
                );
              }
            })()}
          </View>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  dialogInputContainerStyle: {
    marginHorizontal: 8,
  },
});

export default SmartFormNumberInput;
