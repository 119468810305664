import React, { useState, useEffect, useCallback, useMemo } from "react";
import { View } from "react-native";

import { TextInput, HelperText } from "react-native-paper";

const SimpleText = ({
  title,
  placeholder,
  onBlur,
  onChange,
  value,
  invalid,
  customError,
  error,
  operator,
}) => {
  let nestedValue = operator ? value?.[operator] || null : value;
  let _onChange = (data) => {
    if (!operator) onChange(data);
    else {
      onChange((v) => ({ ...v, [operator]: data }));
    }
  };
  return (
    <View style={{ flex: 1 }}>
      <TextInput
        label={title}
        style={{
          backgroundColor: "transparent",
          paddingHorizontal: 0,
        }}
        placeholder={placeholder}
        onBlur={onBlur}
        onChangeText={_onChange}
        value={nestedValue}
      />
      <HelperText type="error" padding="none" visible={invalid}>
        {customError || error?.message}
      </HelperText>
    </View>
  );
};

export default SimpleText;
