import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { View, Dimensions, Platform } from "react-native";
import "fast-text-encoding";
import _Joi from "joi";

const Joi = _Joi.extend((joi) => ({
  base: joi.array(),
  coerce: (value, helpers) => ({
    value: value.split ? value.replace(/^,+|,+$/gm, "").split(",") : value,
  }),
  type: "emailSplit",
}));

import {
  SmartFormToggle,
  SmartFormTextInput,
  SmartFormDatePicker,
  SmartFormRadioGroup,
  SmartFormTextArea,
  SmartFormCheckboxGroup,
  SmartFormNumberInput,
  SmartFormRichTextEditor,
  SmartFormRichTextEditorWeb,
} from "../../Components/SmartForm";

import dot from "dot-object";
import _ from "lodash";

const ViewListItemEdit = forwardRef((props, ref) => {
  const { gql, item } = props;
  const {
    loading: EnumMsgTemplateTypeLoading,
    error: EnumMsgTemplateTypeError,
    data: EnumMsgTemplateTypeData,
  } = useQuery(gql.EnumMsgTemplateType);

  const initForm = useMemo(() => {
    const toOmitFromEdit = ["_id", "createdAt", "updatedAt", "tid", "stats"];
    return _(item).omitGqlPropsDeep().omit(toOmitFromEdit).value();
  }, []);

  const schema = Joi.object({
    type: Joi.string().empty(),
    name: Joi.string().empty(),
    title: Joi.string().empty(),
    body: Joi.string().empty(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      submitCount,
      dirtyFields,
    },
  } = useForm({
    resolver: joiResolver(schema),
    mode: "onTouched",
    defaultValues: initForm,
  });

  useImperativeHandle(ref, () => ({
    submit: ({ onSubmit, onError }) =>
      handleSubmit((data) => {
        console.log("child handel");
        const dotDirtyFields = Object.keys(dot.dot(dirtyFields));
        const update = _.pick(data, dotDirtyFields);
        onSubmit(update);
      }, onError)(),
    reset,
  }));

  return (
    <>
      <SmartFormRadioGroup
        title="Type"
        name="type"
        control={control}
        options={
          EnumMsgTemplateTypeLoading || EnumMsgTemplateTypeError
            ? []
            : EnumMsgTemplateTypeData?.__type?.enumValues.map((v) => ({
                name: v.name,
                value: v.name,
              }))
        }
      />
      <SmartFormTextInput
        title="Name"
        name="name"
        placeholder="Enter Name for the template"
        control={control}
      />
      <SmartFormTextInput
        title="Title"
        name="title"
        placeholder="Enter Title for the template"
        control={control}
      />

      <SmartFormRichTextEditor
        name="body"
        placeholder="Design you template here"
        control={control}
      />
      {/* <SmartFormTextArea
        title="Body"
        name="body"
        placeholder="Enter Content"
        numberOfLines={20}
        control={control}
      /> */}
    </>
  );
});

export default ViewListItemEdit;
