import React, {
  useState,
  useLayoutEffect,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { StyleSheet, View } from "react-native";
import {
  Button,
  TextInput,
  useTheme,
  Text,
  Appbar,
  Chip,
} from "react-native-paper";

import { Select } from "../../Components/Input/";
import { MyRichTextEditor } from "../../Components/SmartForm/SmartFormRichTextEditor";
import { useAlerts } from "react-native-paper-alerts";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";

import * as gql from "./gql";
import isEmail from "validator/es/lib/isEmail";
import SettingHelpers from "../Settings/SettingHelpers";
import hashsum from "hash-sum";

const EmailInputField = ({ value, onChangeText, placeholder }) => {
  const { colors } = useTheme();

  const [input, setInput] = useState("");

  return (
    <>
      {value.map((v) => (
        <Chip key={v} textStyle={{ color: isEmail(v) ? colors.text : "#f00" }}>
          {v}
        </Chip>
      ))}

      <TextInput
        autoCorrect={false}
        mode="flat"
        dense
        style={[styles.input, { backgroundColor: colors.background }]}
        placeholderTextColor={colors.placeholder}
        placeholder={placeholder}
        value={input}
        onChangeText={setInput}
        onKeyPress={(e) => {
          const { keyCode } = e;
          const start = e.target.selectionStart;
          if ([186, 188, 13, 32, 9].includes(keyCode) && input.length) {
            e.preventDefault();
            onChangeText([...value, input]);
            setInput("");
          }
          if (start === 0 && keyCode === 8) {
            console.log("Current value", value);
            let clone = [...value];
            let last = clone.pop();
            if (last) {
              onChangeText(clone);
              setInput(last);
            }
          }
        }}
      />
    </>
  );
};

const EmailThreadCompose = ({ navigation, route }) => {
  const client = useApolloClient();
  const alerts = useAlerts();
  const { colors } = useTheme();
  const { item } = route.params;
  const [task_id, setTask_id] = useState(route.params?.task_id);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState([]);
  const [template, setTemplate] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [forceChange, setForceChange] = useState(false);
  const [oldHash, setOldHash] = useState(null);
  const [ready, setReady] = useState(false);

  // console.log("task_id??", task_id);
  const {
    loading: MsgTemplateManyForSelectLoading,
    error: MsgTemplateManyForSelectError,
    data: MsgTemplateManyForSelectData,
  } = useQuery(gql.MsgTemplateManyForSelect);

  const [refetch] = useLazyQuery(gql.getDraftedThread, {
    onCompleted: (data) => {
      console.log("refetchcomplete");
      let from = data?.result?.draft?.message?.from;
      let to = data?.result?.draft?.message?.to;
      let template = data?.result?.draft?.template_id;
      let title = data?.result?.draft?.message?.subject;
      let content = data?.result?.draft?.message?.html;

      setFrom(from);
      setTo(to);
      setTemplate(template);
      setTitle(title);
      setContent(content);
      setForceChange(true);

      setOldHash(
        hash({
          from,
          to,
          template,
          title,
          content,
        })
      );
      setReady(true);
    },
    onError: (e) => {
      alert("updateChanged " + e);
    },
    variables: { _id: task_id },
    fetchPolicy: "network-only",
  });

  const [updateDraft] = useMutation(gql.updateDraftedThread, {
    onCompleted: (data) => {
      console.log("Update complated", data);
      refetch();
    },
    onError: (e) => {
      alert("updateDraft " + e);
    },
  });
  const [newDraft] = useMutation(gql.createDraftThread, {
    onCompleted: (data) => {
      const new_id = data.result._id;
      console.log("create complated", new_id, data);
      setTask_id(new_id);
      refetch();
    },
    onError: (e) => {
      alert("createDraft " + e);
    },
  });
  const [scheduleDraft] = useMutation(gql.scheduleDraftedThread, {
    onCompleted: (data) => {
      console.log("schedule complated", data);
      refetch();
    },
    onError: (e) => {
      alert("scheduleDraft " + e);
    },
  });

  let templateOptions = useMemo(() => {
    return MsgTemplateManyForSelectData?.msgTemplateMany.map((v) => ({
      name: v.name,
      value: v._id,
    }));
  }, [MsgTemplateManyForSelectData]);

  // Block user from switch template
  const onChangeTemplate = (v) => {
    if (v && (content.length || title.length)) {
      // alert("Please clear the content before switching to a Template");
      alerts.alert(
        "Change of Template",
        "Please clear the content before switching to a Template",
        [
          {
            text: "Yes",
            onPress: () => {
              // setContent("");
              // setTitle("");
              setTemplate(v);
              setForceChange(true);
            },
          },
          {
            text: "Cancel",
            style: "cancel",
          },
        ]
      );
    } else {
      setTemplate(v);
    }
  };
  // Get rendered Template
  useEffect(() => {
    if (!ready) return;
    let run = async () => {
      console.log("Swiching to template/doc_id", template, item._id);
      if (!template) return;
      let message = await client.query({
        query: gql.RenderTemplate,
        variables: { project_id: item._id, template_id: template },
      });
      console.log("useEffect", message);

      let title = message?.data?.message?.title;
      let body = message?.data?.message?.body;

      setContent(body);
      setTitle(title);
      setForceChange(true);
    };
    run();
  }, [template]);

  // Component init
  useEffect(() => {
    if (!task_id) {
      let run = async () => {
        let to;
        let from;
        if (item.contact.emails.length) {
          // console.log("Should set to", item.contact.emails);
          setTo(item.contact.emails);
          to = item.contact.emails;
        }
        if (!from) {
          let email = await SettingHelpers.GetContactDefaultemailfrom(client);
          setFrom(email);
          from = email;
        }
        setOldHash(hash({ from, to, title, content, template }));
        setReady(true);
      };
      run();
    } else {
      refetch();
    }
  }, []);

  const save = useCallback(async () => {
    console.log("Save", task_id, to, from, title, content, template);
    if (task_id) {
      await updateDraft({
        variables: {
          _id: task_id,
          template_id: template,
          from,
          to,
          title,
          content,
        },
      });
      return task_id;
    } else {
      let draft = await newDraft({
        variables: {
          project_id: item._id,
          from,
          to,
          title,
          content,
          template_id: template,
        },
      });

      console.log("created new draft", draft?.data?.result?._id);
      return draft?.data?.result?._id;
    }
  }, [to, from, title, content, template, task_id]);

  const send = useCallback(async () => {
    console.log("Send", to, from, title, content, template);
    let task_id = await save();

    await scheduleDraft({ variables: { _id: task_id } });
    navigation.goBack();
  }, [save]);

  const hash = ({ to, from, template, title, content }) => {
    let message = {
      to,
      from,
      template,
      title,
      content,
    };
    let newHash = hashsum(message);
    console.log("new Hash", newHash, message);
    return newHash;
  };

  let currentHash = useMemo(() => {
    if (!ready) return null;
    return hash({ to, from, template, title, content });
  }, [to, from, template, title, content, ready]);

  const hasUnsavedChanges = oldHash && currentHash && currentHash !== oldHash;
  // console.log("currentHash, oldHash", currentHash, oldHash);

  useEffect(() => {
    if (!ready) return;
    navigation.addListener("beforeRemove", (e) => {
      if (!hasUnsavedChanges) {
        // If we don't have unsaved changes, then we don't need to do anything
        return;
      }

      // Prevent default behavior of leaving the screen
      e.preventDefault();

      // Prompt the user before leaving the screen
      alerts.alert(
        "Discard changes?",
        "You have unsaved changes. Are you sure to discard them and leave the screen?",
        [
          { text: "Don't leave", style: "cancel", onPress: () => {} },
          {
            text: "Discard",
            style: "destructive",
            onPress: () => navigation.dispatch(e.data.action),
          },
        ]
      );
    });
  }, [navigation, hasUnsavedChanges, ready]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: ({ navigation, scene, previous }) => {
        return (
          <Appbar.Header>
            {previous ? (
              <Appbar.BackAction onPress={() => navigation.goBack()} />
            ) : navigation.openDrawer ? (
              <Appbar.Action
                icon="menu"
                onPress={() => navigation.openDrawer()}
              />
            ) : null}
            <Appbar.Content title={scene.descriptor.options.title} />
            {hasUnsavedChanges && (
              <Text style={{ color: colors.surface }}>Modified</Text>
            )}

            <Appbar.Action icon="send-outline" onPress={send} />
            <Button
              mode="outlined"
              color={colors.surface}
              style={[styles.button]}
              onPress={save}
            >
              Save
            </Button>
          </Appbar.Header>
        );
      },
    });
  }, [navigation, save, send, currentHash, hash]);

  if (
    !ready ||
    MsgTemplateManyForSelectLoading ||
    MsgTemplateManyForSelectError
  )
    return <Text>Loading...</Text>;

  const disabled = !!template;
  return (
    <>
      <View style={[{ backgroundColor: colors.background }]}>
        <View style={styles.item}>
          <Text>From: </Text>
          <TextInput
            autoCorrect={false}
            mode="flat"
            dense
            style={[styles.input, { backgroundColor: colors.background }]}
            placeholderTextColor={colors.placeholder}
            placeholder={"Your email"}
            value={from}
            onChangeText={setFrom}
          />
        </View>
        <View style={styles.item}>
          <Text>To: </Text>
          <EmailInputField
            placeholder={"Email (eg: aaa@xx.com, bbb@xx.com)"}
            value={to}
            onChangeText={setTo}
          />
        </View>
        <View style={styles.item}>
          <Text>Template: </Text>
          <Select
            options={[{ name: "Custom", value: null }, ...templateOptions]}
            selected={template}
            setSelected={onChangeTemplate}
          />
        </View>
        <View style={styles.item}>
          <Text>Title: </Text>
          <TextInput
            autoCorrect={false}
            mode="flat"
            dense
            style={[styles.input, { backgroundColor: colors.background }]}
            placeholderTextColor={colors.placeholder}
            placeholder=""
            value={title}
            onChangeText={setTitle}
            disabled={disabled}
          />
        </View>
        {/* <View style={styles.item}>
          <Button title={theme} onPress={onTheme} />
          <Button
            title={disabled ? "enable" : "disable"}
            onPress={onDisabled}
          />
        </View> */}
      </View>
      <MyRichTextEditor
        value={content}
        onChange={setContent}
        disabled={disabled}
        externalChange={forceChange}
        setExternalChange={setForceChange}
      />
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: 12,
  },
  button: {
    margin: 4,
  },

  container: {
    flex: 1,
    backgroundColor: "#efefef",
  },
  nav: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 5,
  },
  rich: {
    // minHeight: 300,
    // flex: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#e3e3e3",
  },
  topVi: {
    backgroundColor: "#fafafa",
  },
  richBar: {
    borderColor: "#efefef",
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  richBarDark: {
    backgroundColor: "#191d20",
    borderColor: "#696969",
  },
  scroll: {
    backgroundColor: "#ffffff",
  },
  scrollDark: {
    backgroundColor: "#2e3847",
  },
  darkBack: {
    backgroundColor: "#191d20",
  },
  item: {
    // borderBottomWidth: StyleSheet.hairlineWidth,
    // borderColor: "#e8e8e8",
    flexDirection: "row",
    // height: 40,
    alignItems: "center",
    paddingHorizontal: 15,
  },

  input: {
    flex: 1,
  },

  tib: {
    textAlign: "center",
    color: "#515156",
  },

  flatStyle: {
    paddingHorizontal: 12,
  },
  inputContainerStyle: {
    margin: 0,
  },
});

export default EmailThreadCompose;
