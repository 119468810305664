import React from "react";

import {
  SmartFormToggle,
  SmartFormTextInput,
  SmartFormDatePicker,
  SmartFormRadioGroup,
  SmartFormTextArea,
  SmartFormCheckboxGroup,
  SmartFormNumberInput,
  SmartFormRichTextEditor,
} from "../../Components/SmartForm";

const NewFormContent = ({ control, extented }) => {
  const {
    EnumProjectSourceLoading,
    EnumProjectSourceError,
    EnumProjectSourceData,
  } = extented;
  return (
    <>
      <SmartFormToggle title="Active" name="active" control={control} />
      <SmartFormDatePicker title="Date" name="date" control={control} />
      <SmartFormRadioGroup
        title="Source"
        name="source"
        control={control}
        options={
          EnumProjectSourceLoading || EnumProjectSourceError
            ? []
            : EnumProjectSourceData?.__type?.enumValues.map((v) => ({
                name: v.name,
                value: v.name,
              }))
        }
      />
      <SmartFormTextInput
        title="Slug"
        name="slug"
        placeholder="Enter URL Slug"
        control={control}
      />
      <SmartFormTextInput
        title="Photo"
        name="photo"
        placeholder="Enter Link for the photo"
        control={control}
      />
      <SmartFormTextInput
        title="Name"
        name="name"
        placeholder="Enter Project Name"
        control={control}
      />
      <SmartFormTextInput
        title="Pledged USD"
        name="pledged_usd"
        placeholder="Enter pledged amount in USD"
        control={control}
      />
      <SmartFormTextInput
        title="Pledged %"
        name="pledged_persentage"
        placeholder="Enter the progress of goal"
        control={control}
      />
      <SmartFormTextInput
        title="Backers Count"
        name="backers_count"
        placeholder="Enter the Number of backers"
        control={control}
      />
      <SmartFormTextInput
        title="Creator"
        name="creator"
        placeholder="EEnter name of the project creator"
        control={control}
      />
      <SmartFormTextInput
        title="Category"
        name="category"
        placeholder="Enter Category for the project"
        control={control}
      />
      <SmartFormTextInput
        title="Link"
        name="link"
        placeholder="Enter Link for the project"
        control={control}
      />
      <SmartFormTextInput
        title="Keywords"
        name="keywords"
        placeholder="Enter Keywords for this project, seperate by (,)comma"
        control={control}
      />
      <SmartFormTextArea
        title="Notes"
        name="notes"
        placeholder="Type something"
        numberOfLines={5}
        control={control}
      />
    </>
  );
};

export default NewFormContent;
