import _ from "lodash";
import omitDeep from "omit-deep-lodash";
import dot from "dot-object";

_.mixin({
  omitGqlPropsDeep: (item) => {
    const toOmitFromGql = ["__typename"];
    // console.log("mixin item", item);
    return omitDeep(item, toOmitFromGql);
  },
});

export { _ };
