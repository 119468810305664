import React, { useState, useEffect, useCallback, useMemo } from "react";

import { useQuery } from "@apollo/client";
import "fast-text-encoding";
import Joi from "joi";

const NewFormSchema = ({ gql, RenderChild, ChildProps }) => {
  const {
    loading: EnumMsgTemplateTypeLoading,
    error: EnumMsgTemplateTypeError,
    data: EnumMsgTemplateTypeData,
  } = useQuery(gql.EnumMsgTemplateType);
  const initForm = useMemo(
    () => ({
      type: EnumMsgTemplateTypeData?.__type?.enumValues[0].name,
      name: "",
      title: "",
      body: "",
    }),
    [EnumMsgTemplateTypeData]
  );
  const schema = Joi.object({
    type: Joi.string().empty(),
    name: Joi.string().empty(),
    title: Joi.string().empty(),
    body: Joi.string().empty(),
  });
  const postFormVariables = (data) => ({
    type: data.type,
    name: data.name,
    title: data.title,
    body: data.body,
  });

  let ready =
    !(EnumMsgTemplateTypeLoading || EnumMsgTemplateTypeError) &&
    EnumMsgTemplateTypeData;
  if (!ready) return null;
  return (
    <RenderChild
      initForm={initForm}
      schema={schema}
      postFormVariables={postFormVariables}
      {...ChildProps}
      // Extra props
      extented={{
        EnumMsgTemplateTypeLoading,
        EnumMsgTemplateTypeLoading,
        EnumMsgTemplateTypeData,
      }}
    />
  );
};

export default NewFormSchema;
