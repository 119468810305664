import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Alert,
  ScrollView,
  StyleSheet,
  View,
  Linking,
  Image,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import {
  DataTable,
  Button,
  TextInput,
  Checkbox,
  useTheme,
  Text,
  Searchbar,
  Card,
  Paragraph,
  Avatar,
  IconButton,
  Menu,
  Divider,
  List,
  Colors,
  Portal,
  Chip,
} from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import _, { debounce } from "lodash";
import ImageViewDialog from "../../Components/Dialog/ImageViewDialog";
// import ViewListItemEdit from "./viewListItemEdit";
import { DialogConfirm, DialogAlert } from "../../Components/Dialog";

import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";

import findByKey from "find-by-key";

const ContactTask = ({ item, contact_task, i, gql }) => {
  const navigation = useNavigation();
  const [showConversationList, setShowConversationList] = useState(false);
  const [getList, { loading, error, data }] = useLazyQuery(
    gql.GetMsgThreadConvList,
    { variables: { _id: contact_task._id } }
  );

  return (
    <View style={{ flexDirection: "column" }}>
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <Text style={{ flex: 1 }}>{i + 1}</Text>
        <Text style={{ flex: 1 }}>{contact_task?.status}</Text>
        <Text style={{ flex: 1 }}>{contact_task?.draft?.message?.subject}</Text>
        <View style={{ flex: 2, flexDirection: "row" }}>
          <Chip
            disabled={!["Schedule", "Draft"].includes(contact_task?.status)}
            style={{ marginHorizontal: 5 }}
            icon="comment-edit-outline"
            onPress={() => {
              navigation.navigate("EmailThreadCompose", {
                item,
                task_id: contact_task._id,
              });
            }}
          >
            Edit/Send
          </Chip>
          <Chip
            disabled={!contact_task?.conversations?.length}
            style={{ marginHorizontal: 5 }}
            icon="playlist-edit"
            onPress={() => {
              setShowConversationList(!showConversationList);
              getList();
            }}
          >
            {showConversationList ? "Close" : "Open"} Conversations
          </Chip>
        </View>
      </View>
      {showConversationList &&
        !(loading || error) &&
        data?.result?.conversations?.length && (
          <View style={{ flexDirection: "column" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Paragraph>Dir</Paragraph>
              <Paragraph>Scheduled</Paragraph>
              <Paragraph>Completed</Paragraph>
              <Paragraph>From/To</Paragraph>
              <Paragraph>Subject</Paragraph>
              <Paragraph>Content</Paragraph>
              <Paragraph>Action</Paragraph>
            </View>
            {data.result.conversations.map((conv, i) => {
              const {
                direction,
                scheduledAt,
                completedAt,
                message: { from, to, subject, text },
              } = conv;
              return (
                <>
                  <Divider />
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text
                      style={{
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {i + 1}. {direction}
                    </Text>

                    <Text
                      style={{
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {scheduledAt &&
                        moment(scheduledAt).format("YYYY-MM-DD HH:mm")}
                    </Text>
                    <Text
                      style={{
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {completedAt &&
                        moment(completedAt).format("YYYY-MM-DD HH:mm")}
                    </Text>
                    <Text
                      style={{
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {direction === "In" ? from : to.join("\n")}
                    </Text>
                    <Text
                      style={{
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {subject?.length > 10
                        ? subject.substring(0, 10) + "..."
                        : subject}
                    </Text>
                    <Text
                      style={{
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {text?.length > 20 ? text.substring(0, 20) + "..." : text}
                    </Text>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      <Chip onPress={() => {}}>View</Chip>
                      <Chip onPress={() => {}}>Delete</Chip>
                    </View>
                  </View>
                </>
              );
            })}
          </View>
        )}
    </View>
  );
};

const ViewListRowItem = (props) => {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { ViewListItemEdit, gql } = props;
  const [item, setItem] = useState(props.item);

  const [visibleContextMenu, setVisibleContextMenu] = useState(false);
  const [ticked, setTicked] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [errorDialog, setErrorDialog] = useState(null);
  const editItem = useRef();

  const [showImageView, setShowImageView] = useState(false);
  const [notes, setNotes] = useState(item.notes);
  const [checkEmailCrawer, setCheckEmailCrawer] = useState(
    !!item?.emailcrawler?.enable
  );

  const [getOne] = useLazyQuery(gql.GetById, {
    variables: { _id: item._id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data?.result) return;
      setItem(data.result);
    },
  });

  const [deleteOne] = useMutation(gql.DeleteById, {
    variables: { _id: item._id },
    onCompleted: (data) => {
      if (!data?.result?.recordId) return;
      setItem({});
    },
    onError: (e) => {
      alert("delete " + e);
    },
  });

  const [updateOne] = useMutation(gql.UpdateById, {
    onCompleted: (data) => {
      console.log("Update Completed!", data);
    },
    onError: (e) => {
      alert("updateChanged " + e);
    },
  });

  const doUpdate = useCallback(
    (update) =>
      updateOne({
        variables: { _id: item._id, update },
      }),
    []
  );

  const debounceChange = useCallback(
    debounce(doUpdate, 2000),
    [] // will be created only once initially
  );

  if (!item._id) return null;

  return (
    <>
      <Card
        style={[
          styles.card,
          { backgroundColor: ticked ? Colors.purple50 : Colors.white },
        ]}
        mode="outlined"
      >
        <Card.Title
          title={item.name}
          subtitle={`By: ${item.creator}`}
          left={(props) => (
            <Avatar.Text
              {...props}
              style={{
                backgroundColor: item.active ? colors.primary : colors.disabled,
              }}
              size={36}
              label={item.sid}
            />
          )}
          right={(props) => (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {item.manualInput && <Paragraph>manualInput</Paragraph>}
              <Menu
                visible={visibleContextMenu}
                onDismiss={() => setVisibleContextMenu(!visibleContextMenu)}
                anchor={
                  <IconButton
                    {...props}
                    icon="dots-vertical"
                    onPress={() => setVisibleContextMenu(!visibleContextMenu)}
                  />
                }
              >
                <Menu.Item
                  icon="pencil"
                  onPress={() => {
                    setVisibleContextMenu(false);
                    setEditMode(true);
                  }}
                  title="Edit"
                  // disabled={!item.manualInput}
                />
                <Menu.Item
                  icon="trash-can-outline"
                  onPress={() => {
                    setVisibleContextMenu(false);
                    setShowDeleteConfirm(true);
                  }}
                  title="Delete"
                  disabled={!item.manualInput}
                />
                <Divider />
                <Menu.Item
                  onPress={() => {}}
                  title="Below are dummies"
                  disabled
                />
                <Menu.Item
                  icon="undo"
                  onPress={() => {}}
                  title="Undo"
                  disabled
                />
                <Menu.Item
                  icon="redo"
                  onPress={() => {}}
                  title="Redo"
                  disabled
                />
                <Divider />
                <Menu.Item
                  icon="content-cut"
                  onPress={() => {}}
                  title="Cut"
                  disabled
                />
                <Menu.Item
                  icon="content-copy"
                  onPress={() => {}}
                  title="Copy"
                  disabled
                />
                <Menu.Item
                  icon="content-paste"
                  onPress={() => {}}
                  title="Paste"
                  disabled
                />
              </Menu>
            </View>
          )}
        />
        <Portal.Host>
          <View style={{ flexDirection: "row" }}>
            <View style={[{ flexDirection: "column" }, styles.cover]}>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() => {
                    setShowImageView(true);
                  }}
                >
                  <Image
                    style={{
                      flex: 1,
                      width: undefined,
                      height: undefined,
                      minHeight: 100,
                      resizeMode: "contain",
                    }}
                    source={{ uri: item.photo }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={[{ flexDirection: "column" }, styles.content]}>
              <Card.Content>
                {editMode && ViewListItemEdit ? (
                  <>
                    <ViewListItemEdit item={item} ref={editItem} gql={gql} />
                  </>
                ) : (
                  <>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Category: </Paragraph>
                      <Text style={styles.data}>{item.category}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Project date: </Paragraph>
                      <Text style={styles.data}>
                        {item.date
                          ? moment(item.date).format("YYYY-MM-DD hh:mm")
                          : "null"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Last Crawled: </Paragraph>
                      <Text style={styles.data}>
                        {item.lastCrawled
                          ? moment(item.lastCrawled).format("YYYY-MM-DD hh:mm")
                          : "null"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Source: </Paragraph>
                      <Text style={styles.data}>{item.source}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Link: </Paragraph>
                      <Text style={styles.data}>{item.link}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Slug: </Paragraph>
                      <Text style={styles.data}>{item.slug}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>
                        Pledged ($USD):
                      </Paragraph>
                      <Text style={styles.data}>
                        ${item.pledged_usd.toFixed(1)}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>
                        Pledged Progress:
                      </Paragraph>
                      <Text style={styles.data}>
                        {item.pledged_persentage}%
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Backer Count: </Paragraph>
                      <Text style={styles.data}>{item.backers_count}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Keywords: </Paragraph>
                      <Text style={styles.data}>{item.keywords}</Text>
                    </View>
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Notes: </Paragraph>
                      <TextInput
                        mode="outlined"
                        style={styles.data}
                        label="Notes"
                        multiline
                        placeholder="Type something"
                        value={notes}
                        onChangeText={(i) => {
                          debounceChange({ notes: i });
                          setNotes(i);
                        }}
                      />
                    </View>
                    <Divider />
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>ContactEmail: </Paragraph>
                      <View style={[styles.data, { flexDirection: "column" }]}>
                        <Text>
                          {item.contact.emails
                            ? item.contact.emails.join(", ")
                            : "Empty"}
                        </Text>
                        <View style={{ flexDirection: "row" }}>
                          <Chip
                            icon="new-box"
                            onPress={() => {
                              navigation.navigate("EmailThreadCompose", {
                                item,
                              });
                            }}
                          >
                            Create E-mail
                          </Chip>
                        </View>
                        <View style={{ flexDirection: "column" }}>
                          <View style={{ flexDirection: "row" }}>
                            <Paragraph style={{ flex: 1 }}>ThreadNo</Paragraph>
                            <Paragraph style={{ flex: 1 }}>Status</Paragraph>
                            <Paragraph style={{ flex: 1 }}>Subject</Paragraph>
                            <Paragraph style={{ flex: 2 }}>Actions</Paragraph>
                          </View>
                          {item?.contact_tasks
                            ?.filter((v) => v)
                            ?.map((contact_task, i) => (
                              <ContactTask
                                key={contact_task._id}
                                item={item}
                                contact_task={contact_task}
                                i={i}
                                gql={gql}
                              />
                            ))}
                          {!item?.contact_tasks?.length && (
                            <Text style={{ textAlign: "center" }}>
                              Empty, Please click Create E-mail
                            </Text>
                          )}
                        </View>
                      </View>
                    </View>
                    <Divider />
                    <View style={styles.row}>
                      <Paragraph style={styles.label}>Email Crawler:</Paragraph>
                      <View style={styles.data}>
                        {(item?.emailcrawler?.result?.length &&
                          item.emailcrawler.result.map((result) => (
                            <Text
                              style={{ flex: 1, flexWrap: "wrap" }}
                              key={result}
                            >
                              {result}
                            </Text>
                          ))) ||
                          null}
                        <Checkbox.Item
                          label="Enable Crawer"
                          status={checkEmailCrawer ? "checked" : "unchecked"}
                          onPress={() => {
                            let result = !checkEmailCrawer;
                            setCheckEmailCrawer(!checkEmailCrawer);
                            debounceChange({
                              emailcrawler: { enable: result },
                            });
                          }}
                        />
                        {(item?.emailcrawler?.lastRun && (
                          <Text>
                            Last Craw:
                            {moment(item.emailcrawler.lastRun).format(
                              "YYYY-MM-DD"
                            )}
                          </Text>
                        )) ||
                          null}
                        {(item?.emailcrawler?.runTime && (
                          <Text>Run time: {item.emailcrawler.runTime}</Text>
                        )) ||
                          null}
                      </View>
                    </View>
                  </>
                )}
              </Card.Content>
            </View>
          </View>
          <Card.Actions>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
              }}
            >
              <Checkbox.Item
                label="Select"
                status={ticked ? "checked" : "unchecked"}
                onPress={() => setTicked(!ticked)}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              {editMode ? (
                <>
                  <Button
                    style={styles.actionButtons}
                    mode="contained"
                    onPress={() => {
                      editItem.current.submit({
                        onSubmit: (data) => {
                          console.log("parent onSubmit");
                          doUpdate(data);
                          editItem.current.reset();
                          setEditMode(false);
                          setTimeout(getOne, 500);
                        },
                        onError: (errors, event) => {
                          console.log("Edit error", errors);
                          let message = findByKey(errors, "message");
                          if (Array.isArray(message)) {
                            setErrorDialog({
                              title: "Validation Error",
                              children: message.join("\n"),
                            });
                          } else {
                            setErrorDialog({
                              title: "Validation Error",
                              children: message,
                            });
                          }
                        },
                      });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    style={styles.actionButtons}
                    onPress={() => {
                      editItem.current.reset();
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={styles.actionButtons}
                    icon="refresh"
                    onPress={getOne}
                  >
                    Refresh
                  </Button>
                </>
              )}
            </View>
          </Card.Actions>
          {showDeleteConfirm && (
            <DialogConfirm
              visible={showDeleteConfirm}
              close={() => setShowDeleteConfirm(false)}
              title="Confirm to delete"
              confirm={() => {
                deleteOne();
                setShowDeleteConfirm(false);
              }}
            >
              <Paragraph style={{ color: Colors.white }}>
                Are you sure to delete "{item.name}"
              </Paragraph>
            </DialogConfirm>
          )}
          {errorDialog && (
            <DialogAlert
              visible={errorDialog}
              close={() => setErrorDialog(null)}
              title={errorDialog && errorDialog?.title}
              children={errorDialog && errorDialog?.children}
            />
          )}
        </Portal.Host>
      </Card>
      <ImageViewDialog
        visible={showImageView}
        close={() => setShowImageView(false)}
        title={`Preview - ${item.name}`}
        source={{ uri: item.photo }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 4,
  },
  card: {
    margin: 12,
  },
  cover: {
    flex: 3,
  },
  content: { flex: 9 },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 0,
    paddingHorizontal: 16,
  },
  label: {
    flex: 2,
  },
  data: {
    flex: 10,
  },
  checkBoxGroup: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionButtons: {
    marginHorizontal: 8,
  },
  surface: {
    padding: 8,
    height: 80,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
    elevation: 4,
  },
});
export default ViewListRowItem;
