import React, { useState, useEffect, useCallback, useMemo } from "react";
import { View } from "react-native";

import { Switch, HelperText } from "react-native-paper";

const Toggle = ({
  value,
  onChange,
  invalid,
  error,
  customError,
}: {
  value: any;
  onChange: (...event: any[]) => void;
  invalid?: boolean;
  error?: any | undefined;
  customError?: string | undefined;
}) => {
  return (
    <View>
      <Switch value={value} onValueChange={() => onChange(!value)} />
      <HelperText type="error" padding="none" visible={invalid}>
        {customError || error?.message}
      </HelperText>
    </View>
  );
};

export default Toggle;
