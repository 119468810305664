import { gql } from "@apollo/client";

export const Pagination = gql`
  query pagination(
    $search: String
    $date: Date
    $source: EnumProjectSource
    $slug: String
    $name: String
    $creator: String
    $category: String
    $link: String
    $notes: String
    $active: Boolean
    $pledged: Float
    $pledged_usd: Float
    $pledged_persentage: Float
    $backers_count: Float
    $keywords: String
    $sid: Float
    $lastCrawled: Date
    $manualInput: Boolean
    $date_Op: FilterFindManyProjectDateOperatorsInput
    $source_Op: FilterFindManyProjectSourceOperatorsInput
    $slug_Op: FilterFindManyProjectSlugOperatorsInput
    $name_Op: FilterFindManyProjectNameOperatorsInput
    $creator_Op: FilterFindManyProjectCreatorOperatorsInput
    $category_Op: FilterFindManyProjectCategoryOperatorsInput
    $link_Op: FilterFindManyProjectLinkOperatorsInput
    $notes_Op: FilterFindManyProjectNotesOperatorsInput
    $active_Op: FilterFindManyProjectActiveOperatorsInput
    $pledged_Op: FilterFindManyProjectPledgedOperatorsInput
    $pledged_usd_Op: FilterFindManyProjectPledged_usdOperatorsInput
    $pledged_persentage_Op: FilterFindManyProjectPledged_persentageOperatorsInput
    $backers_count_Op: FilterFindManyProjectBackers_countOperatorsInput
    $keywords_Op: FilterFindManyProjectKeywordsOperatorsInput
    $sid_Op: FilterFindManyProjectSidOperatorsInput
    $lastCrawled_Op: FilterFindManyProjectLastCrawledOperatorsInput
    $manualInput_Op: FilterFindManyProjectManualInputOperatorsInput
    $page: Int
    $perPage: Int
    $sort: SortFindManyProjectInput
  ) {
    results: projectPagination(
      filter: {
        search: $search
        date: $date
        source: $source
        slug: $slug
        name: $name
        creator: $creator
        category: $category
        link: $link
        notes: $notes
        active: $active
        pledged: $pledged
        pledged_usd: $pledged_usd
        pledged_persentage: $pledged_persentage
        backers_count: $backers_count
        keywords: $keywords
        sid: $sid
        lastCrawled: $lastCrawled
        manualInput: $manualInput
        _operators: {
          date: $date_Op
          source: $source_Op
          slug: $slug_Op
          name: $name_Op
          creator: $creator_Op
          category: $category_Op
          link: $link_Op
          notes: $notes_Op
          active: $active_Op
          pledged: $pledged_Op
          pledged_usd: $pledged_usd_Op
          pledged_persentage: $pledged_persentage_Op
          backers_count: $backers_count_Op
          keywords: $keywords_Op
          sid: $sid_Op
          lastCrawled: $lastCrawled_Op
          manualInput: $manualInput_Op
        }
      }
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      count
      items {
        _id
        sid
        date
        source
        slug
        name
        creator
        category
        link
        notes
        active
        goal
        pledged_usd
        pledged_persentage
        photo
        emailcrawler {
          result
          runTime
          lastRun
          enable
        }
        backers_count
        keywords
        updatedAt
        createdAt
        lastCrawled
        manualInput
        contact {
          emails
        }
        contact_tasks {
          _id
          template_id
          status
          conversations {
            direction
          }
          draft {
            template_id
            forward
            message {
              subject
              html
            }
          }
        }
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const EnumSortBy = gql`
  query EnumSortBy {
    __type(name: "SortFindOneProjectInput") {
      enumValues {
        name
      }
    }
  }
`;
export const GetById = gql`
  query getProjectById($_id: MongoID!) {
    result: projectById(_id: $_id) {
      _id
      sid
      date
      source
      slug
      name
      creator
      category
      link
      notes
      active
      goal
      pledged_usd
      pledged_persentage
      photo
      emailcrawler {
        result
        runTime
        lastRun
        enable
      }
      backers_count
      keywords
      updatedAt
      createdAt
      lastCrawled
      manualInput
      contact {
        emails
      }
    }
  }
`;
export const PostOne = gql`
  mutation projectCreateOne(
    $date: Date
    $source: EnumProjectSource
    $slug: String
    $name: String
    $creator: String
    $link: String
    $notes: String
    $active: Boolean
    $pledged_usd: Float
    $pledged_persentage: Float
    $photo: String
    $backers_count: Float
    $keywords: String
  ) {
    projectCreateOne(
      record: {
        date: $date
        source: $source
        slug: $slug
        name: $name
        creator: $creator
        link: $link
        notes: $notes
        active: $active
        pledged_usd: $pledged_usd
        pledged_persentage: $pledged_persentage
        photo: $photo
        backers_count: $backers_count
        keywords: $keywords
      }
    ) {
      recordId
      error {
        message
      }
    }
  }
`;
export const UpdateById = gql`
  mutation ($_id: MongoID!, $update: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $_id, record: $update) {
      recordId
      error {
        message
      }
    }
  }
`;
export const DeleteById = gql`
  mutation DeleteById($_id: MongoID!) {
    projectDeleteById(_id: $_id) {
      recordId
      error {
        message
      }
    }
  }
`;
// Customs
export const EnumProjectSource = gql`
  query ProjectSource {
    __type(name: "EnumProjectSource") {
      enumValues {
        name
      }
    }
  }
`;
export const GetMsgThreadConvList = gql`
  query ($_id: MongoID!) {
    result: msgThreadById(_id: $_id) {
      template_id
      type
      status
      scheduledAt
      errorAt
      error
      sentAt
      gotreplyAt
      forwardedAt
      updatedAt
      createdAt
      mtid
      conversations {
        _id
        scheduledAt
        completedAt
        direction
        message {
          from
          to
          subject
          text
        }
      }
    }
  }
`;
