import React, { useRef, useState, useEffect } from "react";
import {
  Appearance,
  Button,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import {
  actions,
  getContentCSS,
  RichEditor,
  RichToolbar,
} from "react-native-pell-rich-editor";
import { useForm, Controller } from "react-hook-form";
import { InsertLinkModal } from "./insertLink";
import { EmojiView } from "./emoji";
import FontSizeSelect from "./fontsize.js";

const imageList = [
  "https://img.lesmao.vip/k/h256/R/MeiTu/1293.jpg",
  "https://pbs.twimg.com/profile_images/1242293847918391296/6uUsvfJZ.png",
  "https://img.lesmao.vip/k/h256/R/MeiTu/1297.jpg",
  "https://img.lesmao.vip/k/h256/R/MeiTu/1292.jpg",
];

const phizIcon = require("../../../assets/phiz.png");
const htmlIcon = require("../../../assets/html.png");
const createContentStyle = (theme) => {
  // Can be selected for more situations (cssText or contentCSSText).
  const contentStyle = {
    backgroundColor: "#2e3847",
    color: "#fff",
    caretColor: "red", // initial valid// initial valid
    placeholderColor: "gray",
    // cssText: '#editor {background-color: #f3f3f3}', // initial valid
    contentCSSText: "font-size: 16px; min-height: 200px;", // initial valid
  };
  if (theme === "light") {
    contentStyle.backgroundColor = "#fff";
    contentStyle.color = "#000033";
    contentStyle.placeholderColor = "#a9a9a9";
  }
  return contentStyle;
};

export const MyRichTextEditor = ({
  onChange,
  value,
  disabled,
  externalChange,
  setExternalChange,
}) => {
  const richText = useRef();
  const linkModal = useRef();
  const scrollRef = useRef();

  // Original Consutructor
  const [html, setHtml] = useState();
  const [theme, setTheme] = useState("light");
  const [contentStyle, setContentStyle] = useState(createContentStyle("light"));
  const [emojiVisible, setEmojiVisible] = useState(false);
  const [showFontSizeSelect, setShowFontSizeSelect] = useState(false);
  // const [disabled, setDisabled] = useState(false);

  if (externalChange) {
    richText.current?.setContentHTML(value);
    setExternalChange(false);
  }

  const [a, setA] = useState(() => {});
  useEffect(() => {
    Appearance.addChangeListener(themeChange);
    Keyboard.addListener("keyboardDidShow", onKeyShow);
    Keyboard.addListener("keyboardDidHide", onKeyHide);
    return () => {
      Appearance.removeChangeListener(themeChange);
      Keyboard.removeListener("keyboardDidShow", onKeyShow);
      Keyboard.removeListener("keyboardDidHide", onKeyHide);
    };
  }, []);

  const onKeyHide = () => {};
  const onKeyShow = () => {
    TextInput.State.currentlyFocusedInput() && setEmojiVisible(false);
  };
  const editorInitializedCallback = () => {
    // console.log("editorInitializedCallback");
    // richText.current?.registerToolbar(function (items) {
    //   console.log(
    //     "Toolbar click, selected items (insert end callback):",
    //     items
    //   );
    // });
  };
  const themeChange = ({ colorScheme }) => {
    const theme = colorScheme;
    const contentStyle = createContentStyle(theme);
    setTheme(theme);
    setContentStyle(contentStyle);
  };

  const save = async () => {
    // Get the data here and call the interface to save the data
    let html = await richText.current?.getContentHtml();
    // console.log(html);
    // Benny to do!
    // this.props.navigation.push("preview", { html, css: getContentCSS() });
  };

  const handleHeightChange = (height) => {
    // console.log("editor height change:", height);
  };

  const insertEmoji = (emoji) => {
    richText.current?.insertText(emoji);
    richText.current?.blurContentEditor();
  };

  const handleEmoji = () => {
    Keyboard.dismiss();
    richText.current?.blurContentEditor();
    setEmojiVisible(!emojiVisible);
  };

  const insertVideo = () => {
    richText.current?.insertVideo(
      "https://mdn.github.io/learning-area/html/multimedia-and-embedding/video-and-audio-content/rabbit320.mp4",
      "width: 50%;"
    );
  };

  const changeFontSize = (input) => {
    console.log("set font size", input);
    richText.current?.setFontSize(input);
  };

  const fontSize = (input) => {
    // 1=  10px, 2 = 13px, 3 = 16px, 4 = 18px, 5 = 24px, 6 = 32px, 7 = 48px;
    // const size = [1, 2, 3, 4, 5, 6, 7];
    // this.richText.current?.setFontSize(size[XMath.random(size.length - 1)]);
    // console.log("set font size", input);
    // richText.current?.setFontSize(input);
    Keyboard.dismiss();
    richText.current?.blurContentEditor();
    setShowFontSizeSelect(!showFontSizeSelect);
  };

  const foreColor = () => {
    richText.current?.setForeColor("blue");
  };

  const hiliteColor = () => {
    richText.current?.setHiliteColor("red");
  };

  const insertHTML = () => {
    // this.richText.current?.insertHTML(
    //     `<span onclick="alert(2)" style="color: blue; padding:0 10px;" contenteditable="false">HTML</span>`,
    // );
    richText.current?.insertHTML(
      `<div style="padding:10px 0;" contentEditable="false">
                  <iframe  width="100%" height="220"  src="https://www.youtube.com/embed/6FrNXgXlCGA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>`
    );
  };

  const onPressAddImage = () => {
    // insert URL
    richText.current?.insertImage(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/100px-React-icon.svg.png",
      "background: gray;"
    );
    // insert base64
    // this.richText.current?.insertImage(`data:${image.mime};base64,${image.data}`);
  };

  const onInsertLink = () => {
    // this.richText.current?.insertLink('Google', 'http://google.com');
    linkModal.current?.setModalVisible(true);
  };

  const onLinkDone = ({ title, url }) => {
    richText.current?.insertLink(title, url);
  };

  const onHome = () => {
    // Benny to do
    // this.props.navigation.push("index");
  };

  const onTheme = () => {
    let newTheme = theme === "light" ? "dark" : "light";
    let contentStyle = createContentStyle(newTheme);
    setTheme(newTheme);
    setContentStyle(contentStyle);
  };

  const onDisabled = () => {
    setDisabled(!disabled);
  };

  const handlePaste = (data) => {
    console.log("Paste:", data);
  };

  /**
   * @deprecated Android keyCode 229
   * @param data
   */
  const handleKeyUp = (data) => {
    // console.log('KeyUp:', data);
  };

  /**
   * @deprecated Android keyCode 229
   * @param data
   */
  const handleKeyDown = (data) => {
    // console.log('KeyDown:', data);
  };

  /**
   *
   * @param data
   * @param {string} inputType
   */
  const onInput = ({ data, inputType }) => {
    // console.log(inputType, data)
  };

  const handleMessage = ({ type, id, data }) => {
    let index = 0;
    switch (type) {
      case "ImgClick":
        richText.current?.commandDOM(
          `$('#${id}').src="${imageList[XMath.random(imageList.length - 1)]}"`
        );
        break;
      case "TitleClick":
        const color = ["red", "blue", "gray", "yellow", "coral"];

        // command: $ = document.querySelector
        richText.current?.commandDOM(
          `$('#${id}').style.color='${color[XMath.random(color.length - 1)]}'`
        );
        break;
      case "SwitchImage":
        break;
    }
    console.log("onMessage", type, id, data);
  };

  const handleFocus = () => {
    editorFocus = true;
  };

  const handleBlur = () => {
    editorFocus = false;
  };

  const handleCursorPosition = (scrollY) => {
    // Positioning scroll bar
    scrollRef.current.scrollTo({ y: scrollY - 30, animated: true });
  };

  const { backgroundColor, color, placeholderColor } = contentStyle;
  const dark = theme === "dark";

  return (
    <>
      <SafeAreaView style={[styles.container, dark && styles.darkBack]}>
        <StatusBar
          barStyle={theme !== "dark" ? "dark-content" : "light-content"}
        />
        <RichToolbar
          style={[styles.richBar, dark && styles.richBarDark]}
          flatContainerStyle={styles.flatStyle}
          editor={richText}
          disabled={disabled}
          selectedIconTint={"#2095F2"}
          disabledIconTint={"#bfbfbf"}
          onPressAddImage={onPressAddImage}
          onInsertLink={onInsertLink}
        />

        <RichToolbar
          style={[styles.richBar, dark && styles.richBarDark]}
          flatContainerStyle={styles.flatStyle}
          editor={richText}
          disabled={disabled}
          // iconTint={color}
          selectedIconTint={"#2095F2"}
          disabledIconTint={"#bfbfbf"}
          onPressAddImage={onPressAddImage}
          onInsertLink={onInsertLink}
          // iconSize={24}
          // iconGap={10}
          actions={[
            actions.undo,
            actions.redo,
            actions.insertVideo,
            actions.insertImage,
            actions.setStrikethrough,
            actions.checkboxList,
            actions.insertOrderedList,
            actions.blockquote,
            actions.alignLeft,
            actions.alignCenter,
            actions.alignRight,
            actions.code,
            actions.line,

            actions.foreColor,
            actions.hiliteColor,
            actions.heading1,
            actions.heading4,
            "insertEmoji",
            "insertHTML",
            "fontSize",
          ]} // default defaultActions
          iconMap={{
            insertEmoji: phizIcon,
            [actions.foreColor]: ({ tintColor }) => (
              <Text style={[styles.tib, { color: "blue" }]}>FC</Text>
            ),
            [actions.hiliteColor]: ({ tintColor }) => (
              <Text
                style={[
                  styles.tib,
                  { color: tintColor, backgroundColor: "red" },
                ]}
              >
                BC
              </Text>
            ),
            [actions.heading1]: ({ tintColor }) => (
              <Text style={[styles.tib, { color: tintColor }]}>H1</Text>
            ),
            [actions.heading4]: ({ tintColor }) => (
              <Text style={[styles.tib, { color: tintColor }]}>H3</Text>
            ),
            insertHTML: htmlIcon,
          }}
          insertEmoji={handleEmoji}
          insertHTML={insertHTML}
          insertVideo={insertVideo}
          fontSize={fontSize}
          foreColor={foreColor}
          hiliteColor={hiliteColor}
        />
        {emojiVisible && <EmojiView onSelect={insertEmoji} />}
        {showFontSizeSelect && <FontSizeSelect onSelect={changeFontSize} />}
        <InsertLinkModal
          placeholderColor={placeholderColor}
          color={color}
          backgroundColor={backgroundColor}
          onDone={onLinkDone}
          ref={linkModal}
        />
        <ScrollView
          style={[styles.scroll, dark && styles.scrollDark]}
          keyboardDismissMode={"none"}
          ref={scrollRef}
          nestedScrollEnabled={true}
          scrollEventThrottle={20}
        >
          <RichEditor
            initialFocus={true}
            ref={richText}
            placeholder={""}
            // initialContentHTML={initHTML}
            initialContentHTML={value}
            editorInitializedCallback={editorInitializedCallback}
            useContainer={true}
            onCursorPosition={handleCursorPosition}
            disabled={disabled}
            editorStyle={contentStyle} // default light style
            style={styles.rich}
            // initialHeight={400}
            containerStyle={{ borderRadius: 24 }}
            onChange={onChange}
            onHeightChange={handleHeightChange}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onInput={onInput}
            onMessage={handleMessage}
            onFocus={handleFocus}
            onBlur={handleBlur}
            pasteAsPlainText={true}
          />
          <TextInput
            mode="outlined"
            style={styles.inputContainerStyle}
            label="HTML"
            multiline
            numberOfLines={5}
            placeholder={"View Html"}
            onChangeText={(v) => {
              onChange(v);
              richText.current?.setContentHTML(v);
            }}
            value={value}
            disabled={disabled}
          />
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

const SmartFormRichTextEditor = (props) => {
  const { name, control } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
          <>
            <MyRichTextEditor value={value} onChange={onChange} />
          </>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#efefef",
  },
  nav: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 5,
  },
  rich: {
    // minHeight: 300,
    // flex: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#e3e3e3",
  },
  topVi: {
    backgroundColor: "#fafafa",
  },
  richBar: {
    borderColor: "#efefef",
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  richBarDark: {
    backgroundColor: "#191d20",
    borderColor: "#696969",
  },
  scroll: {
    backgroundColor: "#ffffff",
  },
  scrollDark: {
    backgroundColor: "#2e3847",
  },
  darkBack: {
    backgroundColor: "#191d20",
  },
  item: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#e8e8e8",
    flexDirection: "row",
    height: 40,
    alignItems: "center",
    paddingHorizontal: 15,
  },

  input: {
    flex: 1,
  },

  tib: {
    textAlign: "center",
    color: "#515156",
  },

  flatStyle: {
    paddingHorizontal: 12,
  },
  inputContainerStyle: {
    margin: 0,
  },
});

export default SmartFormRichTextEditor;
