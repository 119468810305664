import React, { useMemo } from "react";

import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import "fast-text-encoding";
import Joi from "joi";

const SearchQuerySchema = ({ gql, RenderChild, ChildProps }) => {
  const {
    loading: EnumMsgTemplateTypeLoading,
    error: EnumMsgTemplateTypeError,
    data: EnumMsgTemplateTypeData,
  } = useQuery(gql.EnumMsgTemplateType);
  const EnumMsgTemplateTypeValue = useMemo(
    () =>
      EnumMsgTemplateTypeLoading || EnumMsgTemplateTypeError
        ? []
        : EnumMsgTemplateTypeData?.__type?.enumValues.map((v) => v.name),
    [EnumMsgTemplateTypeData]
  );

  const {
    loading: EnumSortByLoading,
    error: EnumMSortFindManyMsgTemplateInputError,
    data: EnumSortByData,
  } = useQuery(gql.EnumSortBy);

  const EnumSortByValue = useMemo(
    () =>
      EnumSortByLoading || EnumMSortFindManyMsgTemplateInputError
        ? []
        : _.uniq(
            EnumSortByData?.__type?.enumValues
              .map((v) => v.name.split("_").slice(0, -1).join("_"))
              .filter((v) => !["DELETED", "DELETEDAT", "DELETEDBY"].includes(v))
          ),

    [EnumSortByData]
  );

  const initForm = {
    tid: {
      eq: null,
      ne: null,
      gte: null,
      lte: null,
      op: "",
    },
    type: {
      eq: "",
      ne: "",
      in: "",
      nin: "",
      op: "",
    },
    name: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    title: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    body: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    sortField: "TID",
    sortAscending: false,
  };

  const schema = Joi.object({
    tid: {
      eq: Joi.number().allow(null, ""),
      ne: Joi.number().allow(null, ""),
      gte: Joi.number().allow(null, ""),
      lte: Joi.number().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    type: {
      eq: Joi.string()
        .valid(...EnumMsgTemplateTypeValue)
        .allow(null, ""),
      ne: Joi.string()
        .valid(...EnumMsgTemplateTypeValue)
        .allow(null, ""),
      in: Joi.array()
        .items(Joi.string().valid(...EnumMsgTemplateTypeValue))
        .allow(null, ""),
      nin: Joi.array()
        .items(Joi.string().valid(...EnumMsgTemplateTypeValue))
        .allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    name: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    title: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    body: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    sortField: Joi.any(),
    sortAscending: Joi.boolean().required(),
  });
  // loading: EnumSortByLoading,
  // error: EnumMSortFindManyMsgTemplateInputError,
  // data: EnumSortByData,

  let ready =
    !(EnumMsgTemplateTypeLoading || EnumMsgTemplateTypeError) &&
    EnumMsgTemplateTypeData &&
    !(EnumSortByLoading || EnumMSortFindManyMsgTemplateInputError) &&
    EnumSortByData;
  if (!ready) return null;

  return (
    <RenderChild
      initForm={initForm}
      schema={schema}
      // postFormVariables={postFormVariables}
      postFormVariables={(data) => {
        console.log("TODO!!!", data);
      }}
      {...ChildProps}
      // Extra props
      extented={{
        EnumMsgTemplateTypeLoading,
        EnumMsgTemplateTypeLoading,
        EnumMsgTemplateTypeData,
        EnumSortByLoading,
        EnumMSortFindManyMsgTemplateInputError,
        EnumSortByData,
        EnumMsgTemplateTypeValue,
        EnumSortByValue,
      }}
    />
  );
};
export default SearchQuerySchema;
