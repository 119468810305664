import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";

import { List, Text, TextInput, Appbar } from "react-native-paper";

import graphql from "graphql";
import { useQuery, useApolloClient, gql, useMutation } from "@apollo/client";
import * as SettingGql from "./gql";
import _ from "lodash";

import { Toggle } from "../../Components/Input";

const Item = ({ fieldName, field, getValue, setValue }) => {
  const { name } = field;
  const typeName = field?.type?.name;

  const _name = _(name).startCase();

  const value = getValue(fieldName + name);

  return (
    <List.Item
      title={_name}
      right={(props) => {
        if (typeName === "Boolean") {
          return (
            <Toggle
              value={value}
              onChange={(v) => {
                setValue(fieldName + name, v);
              }}
            />
          );
        } else if (typeName === "String") {
          return (
            <TextInput
              mode="outlined"
              dense
              placeholder={_name}
              value={value}
              onChangeText={(v) => setValue(fieldName + name, v)}
            />
          );
        }
        return <Text>{typeName}</Text>;
      }}
    />
  );
};

const Accordion = ({ field, getValue, setValue }) => {
  const {
    name,
    type: { fields },
  } = field;
  // console.log("Accordion", fields);
  if (!fields.length) return null;
  return (
    <List.Accordion
      left={(props) => <List.Icon {...props} icon="folder" />}
      title={name}
      id={name}
    >
      {fields.map((field) => (
        <Item
          field={field}
          key={field.name}
          getValue={getValue}
          setValue={setValue}
          fieldName={`${name}.`}
        />
      ))}
    </List.Accordion>
  );
};

const SettingChild = ({ fields, settingsGql, navigation }) => {
  const {
    loading: SettingsLoading,
    error: SettingsError,
    data: SettingsData,
  } = useQuery(SettingGql.Setting(settingsGql), [settingsGql]);

  const [updateOne] = useMutation(SettingGql.Update, {
    onCompleted: (data) => {
      console.log("Update Completed!", data);
    },
    onError: (e) => {
      alert("updateChanged " + e);
    },
  });

  const [data, setData] = useState({});

  useEffect(() => {
    setData(_.cloneDeep(SettingsData)?.settingOne);
  }, [SettingsData]);

  const getValue = (name) => {
    return _.get(data, name);
  };

  const setValue = (name, val) => {
    let newData = _.set(_.cloneDeep(data), name, val);
    setData(newData);
  };

  const saveSettings = () => {
    let update = _(data).omitGqlPropsDeep().value();

    updateOne({
      variables: { settingUpdate: update },
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: ({ navigation, scene, previous }) => {
        return (
          <Appbar.Header>
            {previous ? (
              <Appbar.BackAction onPress={() => navigation.goBack()} />
            ) : navigation.openDrawer ? (
              <Appbar.Action
                icon="menu"
                onPress={() => navigation.openDrawer()}
              />
            ) : null}
            <Appbar.Content title={scene.descriptor.options.title} />
            <Appbar.Action icon="content-save" onPress={saveSettings} />
          </Appbar.Header>
        );
      },
    });
  }, [data]);

  // console.log("SettingsData", settingsGql, SettingsData);

  if (!(fields?.fields && SettingsData)) return null;
  return fields?.fields?.map((field) => {
    return (
      <Accordion
        field={field}
        key={field.name}
        getValue={getValue}
        setValue={setValue}
      />
    );
  });
};

const SettingsList = (props) => {
  const client = useApolloClient();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    (async () => {
      const getFieldsDeep = async (name) => {
        let result = await client.query({
          query: SettingGql.GetFieldsByName(name),
        });
        let data = _.cloneDeep(result.data);

        if (data?.__type?.fields?.length) {
          data.__type.fields = data.__type.fields.filter(
            (v) => !["_id", "updatedAt", "createdAt"].includes(v.name)
          );
          for (let field of data.__type.fields) {
            const {
              type: { name },
            } = field;
            if (name) {
              field.type = await getFieldsDeep(name);
            }
          }
        }

        return data.__type;
      };
      let fields = await getFieldsDeep("Setting");
      setFields(fields);
    })();
  }, []);

  const settingsGql = useMemo(() => {
    let query = "";
    if (!fields.fields) return "";
    // console.log("settingGql useMemo", fields.fields);
    query = fields.fields
      .map((v) => {
        let root = v.name;
        let _fields = v?.type?.fields;
        if (_fields.length) {
          root = root + `{\n${_fields.map((v) => v.name).join("\n")}\n}\n`;
        }
        return root;
      })
      .join("\n");
    return query;
  }, [fields]);

  if (!settingsGql) return null;

  return <SettingChild fields={fields} settingsGql={settingsGql} {...props} />;
};

export default SettingsList;
