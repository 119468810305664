import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  Linking,
  Platform,
  Image,
  View,
  ScrollView,
  SafeAreaView,
} from "react-native";

import {
  Paragraph,
  Colors,
  Portal,
  Dialog,
  useTheme,
  Switch,
  TextInput,
  HelperText,
  Text,
} from "react-native-paper";

import { useForm, Controller } from "react-hook-form";

import { DatePickerModal } from "react-native-paper-dates";
import moment from "moment";
import MenuOperators from "./SmartFormMenuOperators";

const SingleDatePicker = ({
  title,
  placeholder,
  invalid,
  error,
  value,
  onChange,
  operator,
}: {
  title?: string | undefined;
  placeholder?: string | undefined;
  invalid: boolean;
  error: any | undefined;
  value: any;
  onChange: (...event: any[]) => void;
  operator?: any | undefined;
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const open = () => setShowPicker(true);
  const close = () => setShowPicker(false);

  let nestedValue = operator ? value?.[operator] || null : value;

  return (
    <View style={{ flex: 1 }}>
      <TextInput
        label={title}
        style={{
          backgroundColor: "transparent",
          paddingHorizontal: 0,
        }}
        placeholder={placeholder}
        value={nestedValue}
        error={invalid}
        onChangeText={onChange}
        {...(Platform.OS === "web"
          ? { onFocus: open }
          : { onTouchStart: open })}
      />
      <DatePickerModal
        mode="single"
        visible={showPicker}
        onDismiss={() => {
          if (operator) {
            onChange({
              ...value,
              [operator]: null,
            });
          } else {
            onChange(null);
          }
          close();
        }}
        date={
          moment(nestedValue).isValid()
            ? moment(nestedValue).toDate()
            : undefined
        }
        onConfirm={(params) => {
          const date = moment(params.date).format("YYYY-MM-DD");
          if (operator) {
            onChange({
              ...value,
              [operator]: date,
            });
          } else {
            onChange(date);
          }
          close();
        }}
      />
      <HelperText type="error" padding="none" visible={invalid}>
        {error?.message}
      </HelperText>
    </View>
  );
};

const SmartFormDatePicker = ({
  visible,
  title,
  name,
  control,
  isQuery,
  customError,
  placeholder,
}: {
  visible: boolean | undefined;
  title: string;
  name: string;
  control: any | undefined;
  isQuery: boolean | undefined;
  customError: string;
  placeholder: string;
}) => {
  if (visible === false) return null;

  const operators = [
    { name: "Not set", value: "" },
    { name: "Equal", value: "eq" },
    { name: "Not equal", value: "ne" },
    { name: "From", value: "gte" },
    { name: "To", value: "lte" },
    { name: "Between", value: "bte" },
  ];
  // const [operator, setOperator] = useState(operators[0].value);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <View style={styles.row}>
          <View>
            <MenuOperators
              title={title}
              visible={isQuery}
              operators={operators}
              operator={value?.op}
              setOperator={(v) => {
                onChange({ ...value, op: v });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              marginHorizontal: 16,
            }}
          >
            {(() => {
              if (isQuery && !value?.op) return <Text>n/a</Text>;
              if (!isQuery || ["eq", "ne", "gte", "lte"].includes(value?.op)) {
                return (
                  <SingleDatePicker
                    // title={title}
                    placeholder={placeholder}
                    invalid={invalid}
                    error={error}
                    value={value}
                    onChange={onChange}
                    operator={value?.op}
                  />
                );
              } else if (value?.op === "bte") {
                return (
                  <>
                    <SingleDatePicker
                      title="min"
                      placeholder="min"
                      invalid={invalid}
                      error={error}
                      value={value}
                      onChange={onChange}
                      operator="gte"
                    />
                    <SingleDatePicker
                      title="max"
                      placeholder="max"
                      invalid={invalid}
                      error={error}
                      value={value}
                      onChange={onChange}
                      operator="lte"
                    />
                  </>
                );
              }
            })()}
          </View>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  dialogInputContainerStyle: {
    marginHorizontal: 8,
  },
});

export default SmartFormDatePicker;
