import React, { useState, useEffect, useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { Paragraph, HelperText, Checkbox } from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { Options, Option } from "./SmartFormOptions";

const toggeValue = (arr, data) => {
  if (arr.includes(data)) {
    return arr.filter((e) => e !== data);
  }
  arr.push(data);
  return arr;
};

const CheckBoxGroup = ({
  value,
  onChange,
  options,
  invalid,
  error,
  operator,
}) => {
  let nestedValue = operator ? value[operator] : value;
  if (!Array.isArray(nestedValue)) nestedValue = [];

  const _onChange = (data) => () => {
    if (!operator) onChange(toggeValue(nestedValue, data));
    else {
      onChange({ ...value, [operator]: toggeValue(nestedValue, data) });
    }
  };

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        {options?.map((v) => (
          <Checkbox.Item
            key={v.value}
            label={v.name}
            status={nestedValue.includes(v.value) ? "checked" : "unchecked"}
            onPress={_onChange(v.value)}
            style={{
              paddingVertical: 0,
              paddingHorizontal: 0,
            }}
          />
        ))}
      </View>

      <HelperText type="error" padding="none" visible={invalid}>
        {error?.message}
      </HelperText>
    </View>
  );
};

const SmartFormCheckboxGroup = ({
  visible,
  title,
  name,
  control,
  smart,
  customError,
  placeholder,
  options,

  value,
  onChange,
  invalid,
  error,
  operator,
}: {
  visible?: boolean | undefined;
  title?: string | undefined;
  name?: string | undefined;
  control?: any | undefined;
  smart?: boolean | undefined;
  customError?: string | undefined;
  placeholder?: string | undefined;
  options: Options | [];

  value?: any | undefined;
  onChange?: (...event: any[]) => void | undefined;
  invalid?: boolean | undefined;
  error?: any | undefined;
  operator?: string | undefined;
}) => {
  if (visible === false) return null;

  if (!control)
    return (
      <CheckBoxGroup
        value={value}
        onChange={onChange}
        options={options}
        invalid={invalid}
        error={error}
        operator={operator}
      />
    );
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <View style={styles.row}>
          <Paragraph>{title}</Paragraph>
          <CheckBoxGroup
            value={value}
            onChange={onChange}
            options={options}
            invalid={invalid}
            error={error}
          />
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  dialogInputContainerStyle: {
    marginHorizontal: 8,
  },
});

export default SmartFormCheckboxGroup;
