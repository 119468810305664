import React, { useState, useEffect, useCallback, useMemo } from "react";

import { useQuery } from "@apollo/client";
import "fast-text-encoding";
import Joi from "joi";

const NewFormSchema = ({ gql, RenderChild, ChildProps }) => {
  const {
    loading: EnumProjectSourceLoading,
    error: EnumProjectSourceError,
    data: EnumProjectSourceData,
  } = useQuery(gql.EnumProjectSource);
  const initForm = useMemo(
    () => ({
      date: "",
      active: true,
      source: EnumProjectSourceData?.__type?.enumValues[0].name,
      slug: "",
      photo: "",
      name: "",
      pledged_usd: 0,
      pledged_persentage: 0,
      backers_count: 0,
      creator: "",
      category: "",
      link: "",
      notes: "",
      keywords: "",
    }),
    [EnumProjectSourceData]
  );
  const schema = Joi.object({
    active: Joi.boolean(),
    date: Joi.any(),
    source: Joi.string(),
    slug: Joi.any(),
    photo: Joi.string().empty("").uri().default(null),
    name: Joi.any(),
    pledged_usd: Joi.number(),
    pledged_persentage: Joi.number(),
    backers_count: Joi.number(),
    creator: Joi.any(),
    category: Joi.any(),
    link: Joi.string().empty("").uri().default(""),
    keywords: Joi.any(),
    notes: Joi.string().empty("").max(2000),
  });

  const postFormVariables = (data) => ({
    date: data.date || null,
    source: data.source,
    slug: data.slug,
    name: data.name,
    creator: data.creator,
    link: data.link,
    notes: data.notes,
    active: data.active,
    pledged_usd: !Number.isNaN(+data.pledged_usd) && +data.pledged_usd,
    pledged_persentage:
      !Number.isNaN(+data.pledged_persentage) && +data.pledged_persentage,
    photo: data.photo,
    backers_count: !Number.isNaN(+data.backers_count) && +data.backers_count,
    keywords: data.keywords,
  });

  let ready =
    !(EnumProjectSourceLoading || EnumProjectSourceError) &&
    EnumProjectSourceData;
  if (!ready) return null;
  return (
    <RenderChild
      initForm={initForm}
      schema={schema}
      postFormVariables={postFormVariables}
      {...ChildProps}
      // Extra props
      extented={{
        EnumProjectSourceLoading,
        EnumProjectSourceLoading,
        EnumProjectSourceData,
      }}
    />
  );
};

export default NewFormSchema;
