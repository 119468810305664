import React, { useMemo } from "react";

import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import "fast-text-encoding";
import Joi from "joi";

const SearchQuerySchema = ({ gql, RenderChild, ChildProps }) => {
  const {
    loading: EnumProjectSourceLoading,
    error: EnumProjectSourceError,
    data: EnumProjectSourceData,
  } = useQuery(gql.EnumProjectSource);
  const EnumProjectSourceValue = useMemo(
    () =>
      EnumProjectSourceLoading || EnumProjectSourceError
        ? []
        : EnumProjectSourceData?.__type?.enumValues.map((v) => v.name),
    [EnumProjectSourceData]
  );

  const {
    loading: EnumSortByLoading,
    error: EnumSortByError,
    data: EnumSortByData,
  } = useQuery(gql.EnumSortBy);

  const EnumSortByValue = useMemo(
    () =>
      EnumSortByLoading || EnumSortByError
        ? []
        : _.uniq(
            EnumSortByData?.__type?.enumValues
              .map((v) => v.name.split("_").slice(0, -1).join("_"))
              .filter((v) => !["DELETED", "DELETEDAT", "DELETEDBY"].includes(v))
          ),

    [EnumSortByData]
  );

  const initForm = {
    sid: {
      eq: null,
      ne: null,
      gte: null,
      lte: null,
      op: "",
    },
    date: {
      eq: null,
      ne: null,
      gte: null,
      lte: null,
      op: "",
    },
    active: {
      eq: "",
      ne: "",
      in: "",
      nin: "",
      op: "",
    },
    lastCrawled: {
      eq: null,
      ne: null,
      gte: null,
      lte: null,
      op: "",
    },
    source: {
      eq: "",
      ne: "",
      in: "",
      nin: "",
      op: "",
    },
    slug: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    name: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    pledged_usd: {
      eq: null,
      ne: null,
      gte: null,
      lte: null,
      op: "",
    },
    pledged_persentage: {
      eq: null,
      ne: null,
      gte: null,
      lte: null,
      op: "",
    },
    backers_count: {
      eq: null,
      ne: null,
      gte: null,
      lte: null,
      op: "",
    },
    creator: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    category: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    link: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    notes: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    keywords: {
      eq: "",
      ne: "",
      contain: "",
      notcontain: "",
      regex: "",
      op: "",
    },
    sortField: "SID",
    sortAscending: false,
  };

  const schema = Joi.object({
    sid: {
      eq: Joi.number().allow(null, ""),
      ne: Joi.number().allow(null, ""),
      gte: Joi.number().allow(null, ""),
      lte: Joi.number().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    date: {
      eq: Joi.date().allow(null, ""),
      ne: Joi.date().allow(null, ""),
      gte: Joi.date().allow(null, ""),
      lte: Joi.date().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    active: {
      eq: Joi.string().valid("T", "F", "N").allow(null, ""),
      ne: Joi.string().valid("T", "F", "N").allow(null, ""),
      in: Joi.array().items(Joi.string().valid("T", "F", "N")).allow(null, ""),
      nin: Joi.array().items(Joi.string().valid("T", "F", "N")).allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    lastCrawled: {
      eq: Joi.date().allow(null, ""),
      ne: Joi.date().allow(null, ""),
      gte: Joi.date().allow(null, ""),
      lte: Joi.date().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    source: {
      eq: Joi.string()
        .valid(...EnumProjectSourceValue)
        .allow(null, ""),
      ne: Joi.string()
        .valid(...EnumProjectSourceValue)
        .allow(null, ""),
      in: Joi.array()
        .items(Joi.string().valid(...EnumProjectSourceValue))
        .allow(null, ""),
      nin: Joi.array()
        .items(Joi.string().valid(...EnumProjectSourceValue))
        .allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    slug: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    name: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    pledged_usd: {
      eq: Joi.number().allow(null, ""),
      ne: Joi.number().allow(null, ""),
      gte: Joi.number().allow(null, ""),
      lte: Joi.number().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    pledged_persentage: {
      eq: Joi.number().allow(null, ""),
      ne: Joi.number().allow(null, ""),
      gte: Joi.number().allow(null, ""),
      lte: Joi.number().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    backers_count: {
      eq: Joi.number().allow(null, ""),
      ne: Joi.number().allow(null, ""),
      gte: Joi.number().allow(null, ""),
      lte: Joi.number().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    creator: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    category: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    link: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    notes: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    keywords: {
      eq: Joi.string().allow(null, ""),
      ne: Joi.string().allow(null, ""),
      contain: Joi.string().allow(null, ""),
      notcontain: Joi.string().allow(null, ""),
      regex: Joi.string().allow(null, ""),
      op: Joi.string().allow(null, ""),
    },
    sortField: Joi.any(),
    sortAscending: Joi.boolean().required(),
  });
  // loading: EnumSortByLoading,
  // error: EnumSortByError,
  // data: EnumSortByData,

  let ready =
    !(EnumProjectSourceLoading || EnumProjectSourceError) &&
    EnumProjectSourceData &&
    !(EnumSortByLoading || EnumSortByError) &&
    EnumSortByData;
  if (!ready) return null;

  return (
    <RenderChild
      initForm={initForm}
      schema={schema}
      // postFormVariables={postFormVariables}
      postFormVariables={(data) => {
        console.log("TODO!!!", data);
      }}
      {...ChildProps}
      // Extra props
      extented={{
        EnumProjectSourceLoading,
        EnumProjectSourceLoading,
        EnumProjectSourceData,
        EnumSortByLoading,
        EnumSortByError,
        EnumSortByData,
        EnumProjectSourceValue,
        EnumSortByValue,
      }}
    />
  );
};
export default SearchQuerySchema;
