import * as React from "react";
import { I18nManager, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Updates from "expo-updates";

import { useKeepAwake } from "expo-keep-awake";
import { StatusBar } from "expo-status-bar";
import { InitialState, NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import {
  Provider as PaperProvider,
  DarkTheme,
  DefaultTheme,
} from "react-native-paper";
import App from "./RootNavigator";
import DrawerItems from "./DrawerItems";
import { SafeAreaProvider } from "react-native-safe-area-context";

import Constants from "expo-constants";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

import { useMediaQuery } from "react-responsive";

import { AlertsProvider } from "react-native-paper-alerts";

import {} from "./helpers";

const { API_URL } = Constants.manifest.extra;

const client = new ApolloClient({
  uri: API_URL + "/graphql",
  cache: new InMemoryCache(),
});

const PERSISTENCE_KEY = "NAVIGATION_STATE";
const PREFERENCES_KEY = "APP_PREFERENCES";
const PreferencesContext = React.createContext(null);

const CustomDefaultTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    customColor: "#BADA55",
  },
  fonts: {
    ...DefaultTheme.fonts,
    superLight: { ...DefaultTheme.fonts["light"] },
  },
  userDefinedThemeProperty: "",
  animation: {
    ...DefaultTheme.animation,
    customProperty: 1,
  },
};

const CustomDarkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    customColor: "#BADA55",
  },
  fonts: {
    ...DarkTheme.fonts,
    superLight: { ...DarkTheme.fonts["light"] },
  },
  userDefinedThemeProperty: "",
  animation: {
    ...DarkTheme.animation,
    customProperty: 1,
  },
};

const DrawerContent = ({ navigation }) => {
  return (
    <PreferencesContext.Consumer>
      {(preferences) => (
        <DrawerItems
          toggleTheme={preferences.toggleTheme}
          toggleRTL={preferences.toggleRtl}
          isRTL={preferences.rtl}
          isDarkTheme={preferences.theme.dark}
        />
      )}
    </PreferencesContext.Consumer>
  );
};

const Drawer = createDrawerNavigator();

export default function MyApp() {
  useKeepAwake();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 992 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();
  const [theme, setTheme] = React.useState(CustomDefaultTheme);
  const [rtl, setRtl] = React.useState(I18nManager.isRTL);

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
        const state = JSON.parse(savedStateString || "");

        setInitialState(state);
      } catch (e) {
        // ignore error
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  React.useEffect(() => {
    const restorePrefs = async () => {
      try {
        const prefString = await AsyncStorage.getItem(PREFERENCES_KEY);
        const preferences = JSON.parse(prefString || "");

        if (preferences) {
          // eslint-disable-next-line react/no-did-mount-set-state
          setTheme(
            preferences.theme === "dark" ? CustomDarkTheme : CustomDefaultTheme
          );

          if (typeof preferences.rtl === "boolean") {
            setRtl(preferences.rtl);
          }
        }
      } catch (e) {
        // ignore error
      }
    };

    restorePrefs();
  }, []);

  React.useEffect(() => {
    const savePrefs = async () => {
      try {
        await AsyncStorage.setItem(
          PREFERENCES_KEY,
          JSON.stringify({
            theme: theme === DarkTheme ? "dark" : "light",
            rtl,
          })
        );
      } catch (e) {
        // ignore error
      }

      if (I18nManager.isRTL !== rtl) {
        I18nManager.forceRTL(rtl);
        if (!__DEV__) {
          Updates.reloadAsync();
        }
      }
    };

    savePrefs();
  }, [rtl, theme]);

  const preferences = React.useMemo(
    () => ({
      toggleTheme: () =>
        setTheme((theme) =>
          theme === CustomDefaultTheme ? CustomDarkTheme : CustomDefaultTheme
        ),
      toggleRtl: () => setRtl((rtl) => !rtl),
      rtl,
      theme,
    }),
    [rtl, theme]
  );

  if (!isReady) {
    return null;
  }
  return (
    <ApolloProvider client={client}>
      <PaperProvider theme={{ ...theme, isMobile, isTablet, isDesktop }}>
        <AlertsProvider>
          <SafeAreaProvider>
            <PreferencesContext.Provider value={preferences}>
              <React.Fragment>
                <NavigationContainer
                  initialState={initialState}
                  onStateChange={(state) =>
                    AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
                  }
                >
                  <Drawer.Navigator drawerContent={() => <DrawerContent />}>
                    <Drawer.Screen
                      name="Home"
                      component={App}
                      options={{
                        headerShown: false,
                      }}
                    />
                  </Drawer.Navigator>

                  <StatusBar style="light" />
                </NavigationContainer>
              </React.Fragment>
            </PreferencesContext.Provider>
          </SafeAreaProvider>
        </AlertsProvider>
      </PaperProvider>
    </ApolloProvider>
  );
}
