import React, { useState, useEffect, useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { Paragraph, TextInput, HelperText } from "react-native-paper";

import { useForm, Controller } from "react-hook-form";

const SmartFormTextArea = ({
  visible,
  title,
  name,
  control,
  smart,
  customError,
  placeholder,
  numberOfLines,
}: {
  visible: boolean | undefined;
  title: string;
  name: string;
  control: any | undefined;
  smart: boolean | undefined;
  customError: string;
  placeholder: string;
  numberOfLines: number;
}) => {
  if (visible === false) return null;
  return (
    <View style={styles.dialogInputContainerStyle}>
      <Paragraph>{title}</Paragraph>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => (
          <>
            <TextInput
              mode="outlined"
              style={styles.inputContainerStyle}
              label={title}
              multiline
              numberOfLines={numberOfLines}
              placeholder={placeholder}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
            <HelperText type="error" padding="none" visible={invalid}>
              {customError || error?.message}
            </HelperText>
          </>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  dialogInputContainerStyle: {
    marginHorizontal: 8,
  },
  inputContainerStyle: {
    margin: 0,
  },
});

export default SmartFormTextArea;
