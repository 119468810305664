import * as gql from "./gql";
const GetContactDefaultemailfrom = async (client) => {
  let result = await client.query({
    query: gql.GetContactDefaultemailfrom,
  });

  // console.log("GetContactDefaultemailfrom", result);
  return result?.data?.setting?.contact?.defaultemailfrom;
};

export default { GetContactDefaultemailfrom };
