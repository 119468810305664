import * as React from "react";
import { Paragraph, Button, Portal, Dialog, Colors } from "react-native-paper";

const DialogWithCustomColors = ({
  visible,
  close,
  title,
  children,
}: {
  visible: boolean;
  close: () => void;
  title: String;
  children: String;
}) => (
  <Portal>
    <Dialog
      onDismiss={close}
      style={{ backgroundColor: Colors.purple900 }}
      visible={visible}
    >
      <Dialog.Title style={{ color: Colors.white }}>
        {title || "Alert"}
      </Dialog.Title>
      <Dialog.Content>
        <Paragraph style={{ color: Colors.white }}>{children || ""}</Paragraph>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color={Colors.white} onPress={close}>
          OK
        </Button>
      </Dialog.Actions>
    </Dialog>
  </Portal>
);

export default DialogWithCustomColors;
